/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loader-child.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "./loader-child.component";
var styles_LoaderChildComponent = [i0.styles];
var RenderType_LoaderChildComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoaderChildComponent, data: {} });
export { RenderType_LoaderChildComponent as RenderType_LoaderChildComponent };
export function View_LoaderChildComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "container loader-child-bg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "col-md-12 col-sm-12 spinner-text-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "col-md-12 col-sm-12 loader-child-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["class", "fa fa-fw fa-spinner loading-spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "col-md-12 col-sm-12 loader-child-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 8).transform(_co.loaderChildMessage)); _ck(_v, 7, 0, currVal_0); }); }
export function View_LoaderChildComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-loader-child", [], null, null, null, View_LoaderChildComponent_0, RenderType_LoaderChildComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoaderChildComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoaderChildComponentNgFactory = i1.ɵccf("app-loader-child", i3.LoaderChildComponent, View_LoaderChildComponent_Host_0, { loaderChildMessage: "loaderChildMessage" }, {}, []);
export { LoaderChildComponentNgFactory as LoaderChildComponentNgFactory };
