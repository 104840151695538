<app-modal #comModal [showModal]="showModalCom" title='Message'  modalWidth="80" modalHeight="80" (closeModalFrom2NDParent)="showModalCom=false;getAllcoms()">

  <div class="text-center" *ngIf="showModalCom">

    <div class="col-md-12 col-xs-12 col-sm-12 text-center login-form" style="padding:0px 20px">
      <table class="modal-table">
        <tr><td class= "modal-table-tr-field"><b>{{"FROM"|translate}} :</b></td><td><b>{{this.selectedCommunication.sender}}</b></td></tr>
        <tr><td class= "modal-table-tr-field"><b>{{"DATE"|translate}} :</b></td><td><b>{{this.selectedCommunication.date}}</b></td></tr>
        <tr><td class= "modal-table-tr-field"><b>{{"SUBJECT"|translate}} :</b></td><td><b>{{this.selectedCommunication.subject}}</b></td></tr>  
      </table><br>      
      <div class="body" style="text-align: left;" [innerHTML]="this.selectedCommunication.body">
      </div>
      <!-- <button type="submit" style="margin-top: 20px" (click)="showModalCom=false">
        {{"CLOSE"|translate}}
      </button> -->
    </div>
    <h4 *ngIf="selectedCommunication.files.length">Attachments</h4>
    <div class="files col-md-12 col-xs-12 col-sm-12">
      <div class="file-btn col-md-2 col-xs-2 col-sm-2" *ngFor="let file of selectedCommunication.files">
        <div class="file-preview">
          &lt; {{file.file_path.split('.').pop().toUpperCase()}} /&gt;
        </div>
        <button class="file-open" (click)="openAttachment(file.file_path)">Open</button>
      </div>
    </div>
  </div>

</app-modal>

<section>
  <div class="row page-title">
    <div class="clear-padding text-center flight-title flight-bg">
      <h1>{{"COMMUNICATIONS"|translate}}</h1>        
    </div>
  </div>
</section>

<div class="top-baner bg-grey-2">
  <div class="container clear-padding">

    <div class="row no-margin">
      <div class="find-form no-padding col-xs-12 col-md-12">

        <div class="col-sm-12 col-xs-12 clear-padding">

          <table id="table1" class="table table-align">
            <thead>
              <tr>   
                
                <th scope="col">
                  {{"FROM"|translate}} 
                </th>

                <th scope="col">
                  {{"SUBJECT"|translate}}
                </th>

                <th scope="col">
                  {{"DATE"|translate}} 
                </th>                            
                
              </tr>
            </thead>

            <tbody>
              <ng-container *ngFor="let com of communications; index as i">
                <tr class="table-line" [ngClass]="{'unread': !com.read}" valign="middle" (click)="openCommunication(com)">
                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold sender">{{com.sender}}</span>
                  </td>

                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold">{{com.subject}}</span>
                  </td>

                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold">{{com.date}}</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <div class="pagination">
            <b>{{total}} </b>  <span style="text-transform: lowercase;">{{"COMMUNICATIONS"|translate}}</span>
            <div class="per-page">
              <span style="margin-right: 5px;">{{"PER_PAGE"|translate}} : </span>
              <!-- <input type="number" [(ngModel)]="perPage"  min="1" max="100" style="margin-right: 5px;"> -->
              <select name="perPage" [(ngModel)]="perPage" (change)="page=1;getAllcoms()">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>                
              </select>
              <!-- <button (click)="getAllcoms()">{{"RELOAD"|translate}}</button> -->
            </div>
            <div class="per-page">
              <span style="margin-right: 5px;">{{"PAGE"|translate}} : </span>
              <!-- <input type="number" [(ngModel)]="perPage"  min="1" max="100" style="margin-right: 5px;"> -->
              <select name="perPage" [(ngModel)]="page" (change)="getAllcoms()">
                <option *ngFor="let page_i of numbers" [value]="page_i + 1">
                  {{page_i+1}}
                </option>
                           
              </select>
              <!-- <button (click)="getAllcoms()">{{"RELOAD"|translate}}</button> -->
            </div>
          </div>

        </div>

      </div>
    </div>
  
  </div>
</div>