import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { AGENCY_UPDATE_B2B, AGENCY_SESSION } from "./urls_b2b";

@Injectable()

export class AgencyDashboardService {

    constructor(private baseService: BaseService) {
    }


    getAgency() {
        return this.baseService.tokenGetRequest(AGENCY_SESSION);
    }

    updateAgency(agency) {
        return this.baseService.tokenPutRequest(AGENCY_UPDATE_B2B, agency);
    }

}
