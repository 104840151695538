import { environment } from '../../../environments/environment';

export const HOME_ROUTE = '/home';
export const FLIGHT_BOOKING_ROUTE = '/flight-search';
export const HOTEL_BOOKING_ROUTE = '/hotel-search';
export const USER_DASHBOARD_ROUTE = '/user-dashboard';
export const PACKAGE_BOOKING_ROUTE = '/package-search';
export const CHARTER_BOOKING_ROUTE = '/charter-search';
export const VISA_ROUTE = '/visa';
export const E_CONFIRMATION_ROUTE = '/confirmation';
export const B2B_ROUTE = '/b2b';

export const AVAILABILITY_CHILD_ROUTE = '/availability';
export const CONFIRMATION_CHILD_ROUTE = '/confirmation';

export const HOTEL_VOUCHER_ROUTE = '/voucher';
export const HOTEL_ITEM_DETAILS = '/details';

export const FLIGHT_PAX = '/passenger';
export const PACKAGE_PAX = '/traveller';
export const HOTEL_PAX = '/guest';
export const VISA_PAX = '/passenger-visa';
export const CHARTER_PAX = '/charter-passenger';
export const E_CONFIRMATION_CHILD = '/confirmOrder/';
export const LOGIN_B2B = '/login';
export const FLIGHT_SCHEDULE = '/flight-schedule';
export const FLIGHT_CALENDAR = '/flight-calendar';
export const MANAGE_FLIGHTS_B2B = '/manage-flight-bookings';
export const MANAGE_VISA_B2B = '/manage-visa';
export const MANAGE_HOTELS_B2B = '/manage-hotel-bookings';
export const MANAGE_FLIGHT_ETICKET_B2B = '/manage-flight-bookings/eticket/';
export const EDIT_FLIGHT_TICKET = '/edit-flight-bookings/eticket/';
export const MANAGE_FLIGHT_AMADEUS_MARKUP_B2B = '/manage-flight-bookings/amadeus-markup-manager-agency';
export const MANAGE_HOTELS_VOUCHER_B2B = '/manage-hotel-bookings/voucher/';
export const ACCOUNT_HISTORY_B2B = '/account-history';
export const MANAGE_AGENT_B2B = '/manage-agent';
export const MARKUP_HISTORY_B2B = '/markup-history';
export const AGENCY_DASHBOARD_B2B = '/agency-dashboard';
export const CHANGE_PASSWORD_B2B = '/change-password';
export const FORGET_PASSWORD_B2B = '/forget-password';
export const RESET_PASSWORD_B2B = '/reset-password';
export const REGISTER_B2B = '/register';
export const CREATE_INSURANCE = "/insurance";
export const CREATE_VISA = "/visa";
export const CREATE_CHARTER = "/charter";
export const CREATE_PACKAGE = "/package";
export const MANAGE_PACKAGE_BOOKINGS_B2B = '/manage-package-bookings';
export const MANAGE_PACKAGES_B2B = '/manage-packages';
export const MANAGE_PACKAGE_VOUCHER_B2B = '/manage-packages/voucher/';
export const PACKAGE_DETAILS_PAGE = '/package-details';
export const COMMUNICATIONS_DETAILS_PAGE = '/communication';
export const INVOICES_PAGE = '/invoices';
export const INSURANCES_MANAGE_PAGE = '/assurance-manage';
export const CHARTER_MANAGE_PAGE = '/charter-manage';
export const CHARTER_ETICKET_PAGE = '/charter-manage/eticket';

export const noParamsRoutes = [HOME_ROUTE];
export const MANAGE_VISA_REQUEST = '/manage-visa/';
