
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { USER_API_URL, LOGIN_API_URL, URL_PARAMS, UrlBuilder, CONFIRM_B2C_ACCOUNT, B2C_FORGET_PASSWORD, B2C_RESET_PASSWORD } from '@app/shared/services/urls_b2c';
import { ADMIN_LOGIN, AGENCY_SESSION, ADMIN_LOGOUT, ADMIN_CHANGE_PASSWORD, ADMIN_RESET_PASSWORD, ADMIN_FORGET_PASSWORD } from '@app/shared/services/urls_b2b';
import { CURRENT_PLATFORM_API_URL } from '@app/shared/services/urls_common';
import { BaseService } from '@app/shared/services/base.service';
import { ShareVars } from './share.vars';


@Injectable()
export class LoginService {

    constructor(private baseService: BaseService) { }

    
    getCurrentPlatform(){        
        return this.baseService.tokenGetRequest(CURRENT_PLATFORM_API_URL);
    }

    verifyToken() {

        let correctURL;

        if (this.baseService.isB2BUserCookies()) {

            correctURL = AGENCY_SESSION;
            ShareVars.waitingB2BUser = true;

            this.baseService.getB2BUserService().subscribe(

                data => {

                    const hasError = data['error'];

                    if (!hasError) {
                        this.baseService.setB2BUser(data);
                    } else {
                        this.baseService.setB2BUser(null);
                        this.baseService.deleteUserTypeCookies();
                        ShareVars.authorizations = [];
                        ShareVars.waitingB2BUser = false;
                    }
                }
            );


        } else {

            this.baseService.deleteUserTypeCookies();
            correctURL = USER_API_URL;
        }

        return this.baseService.tokenGetRequest(correctURL).pipe(
            tap((data: any) => {
                if (correctURL == AGENCY_SESSION) ShareVars.currentAgency = data;
                if (data.session) {
                    this.baseService.setToken(data.session.token);
                }
            },
                (error) => console.log("service error", error)
            )
        );
    }

    logIn(email, password) {
        const body = {};
        body [URL_PARAMS.EMAIL] = email;
        body [URL_PARAMS.PASSWORD] = password;
        body [URL_PARAMS.REMEMBER_ME] = true;

        return this.baseService.tokenPostRequest(LOGIN_API_URL, body);
    }

    isLoggedIn() {
        return !!this.getUser();
    }

    getUser() {
        if (this.baseService.getToken()) {
            return this.baseService.getUser();
        }
        return null;
    }

    getGuestToken() {

    }


    logout() {
        this.baseService.deleteToken();
        this.baseService.deleteB2BUser();
        this.baseService.deleteUserTypeCookies();
        window.location.reload();
    }

    /* B2B */

    adminLogin(username, password, activationCode?) {

        const body = {};
        
        body [URL_PARAMS.USERNAME] = username;
        body [URL_PARAMS.PASSWORD] = password;
        if (activationCode) {
            body [URL_PARAMS.ACTIVATION_CODE] = activationCode;
        } 
        body [URL_PARAMS.REMEMBER_ME] = true;


        return this.baseService.tokenPostLoginRequest(ADMIN_LOGIN, body);
    }

    logoutB2B() {
        return this.baseService.tokenGetRequest(ADMIN_LOGOUT);
    }

    changePassword(data) {
        return this.baseService.tokenPostRequest(ADMIN_CHANGE_PASSWORD, data);
    }

    forgetPassword(data, type) {
        let url = ADMIN_FORGET_PASSWORD;
        if (type==='b2c') {
            url = B2C_FORGET_PASSWORD;
        }
        return this.baseService.tokenPostRequest(url, data);
    }

    resetPassword(token, data) {
        return this.baseService.resetPassword(ADMIN_RESET_PASSWORD, token, data);
    }

    resetB2CPassword(data){
        return this.baseService.tokenPostRequest(B2C_RESET_PASSWORD, data)
    }

    confirmB2cAccount(token) {
        // return this.baseService.tokenPostRequest(CONFIRM_B2C_ACCOUNT, []);
        return this.baseService.getRequestCode(CONFIRM_B2C_ACCOUNT + '/' + token, {})
    }
}

