import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { FlightService } from 'app/shared/services/flight.service';
import { BaseService } from 'app/shared/services/base.service';
import { ShareVars } from "@app/shared/services/share.vars";
import { NavigationService } from "@app/shared/services/navigation.service";
import { cloneDeep } from 'lodash';

@Component({
    selector: 'app-flight-calendar',
    templateUrl: './flight-calendar.component.html',
    styleUrls: ['./flight-calendar.component.scss']
})
export class FlightCalendarComponent implements OnInit {

    red = [231, 76, 60] // 1
    orange = [241, 196, 15] // 0.5
    green = [46, 204, 113] // 0

    minp = 100000000000;
    maxp = 0;
    isSelected = false;
    data = null;
    originalData = null;
    request = null;
    number_of_stops = [[], []]; //[outbound,inbound] 
    airlines = new Set();
    airlineFilterNames = [];
    inbound_stops_filters = [];
    outbound_stops_filters = [];
    filtersState = [];
    loading = true;
    constructor(private flightService: FlightService, private navigationService: NavigationService,
        private baseService: BaseService, private activatedRoute: ActivatedRoute) {
        ShareVars.isBusy = true;
    }

    ngOnInit() {

        const searchDataService = this.flightService.getFlightBookingSearchData();
        let queryParams = this.flightService.getFlightSearchParams("flightSearchParams");
        if (!searchDataService) {
            if (!queryParams) {
                this.navigationService.goToHomePage();
                return;
            }
            queryParams = JSON.parse(queryParams);
            this.request = queryParams;
        } else {
            this.request = searchDataService;
        }
        this.request["range"] = 3;

		this.flightService.getProviders().subscribe(data => {
        let requestInfo = {
            totalErrors :0,
            totalRequests :0,
            directFlight: this.request["directFlight"],
            providers : data
        }
        requestInfo["limit"] = requestInfo.directFlight ? requestInfo.providers.length : requestInfo.providers.length * 2;
        for (let provider of data) {
            this.request['provider_code'] = provider.code;
            this.request['provider_id'] = provider.id;
            this.handleRequest(requestInfo);
            if(!requestInfo.directFlight){
                this.request["directFlight"] = true;
                this.handleRequest(requestInfo)
                this.request["directFlight"] = false;
            }
        }
		});
    }
    handleRequest(requestInfo){

        this.flightService.getFlightCalendar(this.request, false).subscribe(data => {
        requestInfo.totalRequests++;
        if(requestInfo.totalRequests == requestInfo.limit) {
            this.loading = false;
        }
            if (data['status'] === 204 || data['status'] === 500 || (data.result && data.result.error)) {
                requestInfo.totalErrors++;
                this.handleSubscribeError(requestInfo);
                return;
            }

            let array_of_prices = Object.keys(data.result.recommendations).map(el => data.result.recommendations[el].price.detailed_price.total)
            let min_in_data = Math.min(...array_of_prices);
            let max_in_data = Math.max(...array_of_prices);
            this.minp = this.minp > min_in_data ? min_in_data : this.minp;
            this.maxp = this.maxp < max_in_data ? max_in_data : this.maxp;

            this.mergeData(data);
            //let pricesArray = Object.keys(this.data.result.heatMap).map(el => Number(el));
            //console.log(this.minp, this.maxp, pricesArray)
            ShareVars.isBusy = false;
        }, error => {
			requestInfo.totalErrors++;
			requestInfo.totalRequests++;
            this.handleSubscribeError(requestInfo);

        });
    }

    handleSubscribeError(requestInfo) {

        if (requestInfo.totalErrors == requestInfo.limit ) {
            ShareVars.showError = true;
            ShareVars.errorTitle = "ERROR";
            ShareVars.errorMessage = "NO_RESULTS_FOUND";
            this.navigationService.goToHomePage();
            return;
        }
        ShareVars.showError = false;
        return;
    }

    mergeData(new_data) {
        let isFirstData = false;
        if (!this.data) {
            isFirstData = true;
            this.data = new_data;
        }
        for (let rec of Object.keys(this.data.result.recommendations)) {
            if (isFirstData) {
                this.data.result.recommendations[rec] = [this.data.result.recommendations[rec]];
            } else {
                this.data.result.recommendations[rec].push(new_data.result.recommendations[rec]);
                this.data.result.recommendations[rec].sort((a, b) => a.price.detailed_price.total - b.price.detailed_price.total);
            }
            new_data.result.recommendations[rec].provider = new_data.provider;
            new_data.result.recommendations[rec].flightNeedExchangeDocuments = new_data.result.flightNeedExchangeDocuments;
            for (let stop of this.data.result.recommendations[rec]) {

                this.number_of_stops[0][stop.bound_1] = true;
                if (stop.bound_2 != undefined) {
                    this.number_of_stops[1][stop.bound_2] = true;
                }
                this.airlines.add(stop.validatingCarrier)
            }
        }


        this.originalData = cloneDeep(this.data);

    }

    flightItem(outbound, inbound) {
        let item = this.data.result.recommendations[this.reformatDate(outbound, inbound)];
        return item ? item[0] : null;
    }

    getBounds(outbound, inbound, i) {
        let item = this.flightItem(outbound, inbound)["bound_" + i];

        return item == 0 ? "Direct" : `${item} Escale(s)`
    }

    getPrice(outbound, inbound) {
        let item = this.flightItem(outbound, inbound);
        if (!item) {
            return;
        }
        let price = item.price.total;
        let cur = item.price.detailed_price.currency;
        return [price, cur];
    }

    getPriceDetailed(outbound, inbound) {
        let item = this.flightItem(outbound, inbound);
        if (!item) {
            return;
        }
        var price = item.price.detailed_price.total;
        price = (Math.round(price * 100) / 100).toFixed(2);
        let cur = item.price.detailed_price.currency;
        return [price, cur];
    }

    formatPrice(outbound,inbound){
        let price = this.getPriceDetailed(outbound,inbound);
        return [this.baseService.formatPrice(price[0]),price[1]];
    }


    reformatDate(outbound, inbound) {
        let outb = outbound.substring(2).split("-").reverse().join("");
        if (inbound) {
            let inb = inbound.substring(2).split("-").reverse().join("");
            return `${outb}-${inb}`
        }
        return `${outb}`
    }
    getAirlineLogo(outbound, inbound) {
        let item = this.flightItem(outbound, inbound);
        if (!item) {
            return;
        }
        return item.validatingCarrier;
    }

    setSelectedFlight(outbound, inbound) {
        this.isSelected = true;
        this.request["departureDate_1"] = outbound;
        if (inbound) {
            this.request["departureDate_2"] = inbound;
        }
    }

    goToAvailability() {
        this.flightService.setFlightSearchParams("flightSearchParams", this.request);
        this.flightService.setFlightBookingSearchData(this.request);
        this.navigationService.goToFlightAvailabilityPage();
    }

    calculateRgb(p) {
        if (this.minp == this.maxp) { return "rgb(" + this.green[0] + ", " + this.green[1] + ", " + this.green[2] + ")" }
        let x = (Number(p) - this.minp) / (this.maxp - this.minp)
        var rgb = this.rgbInterpolation(x)
        return "rgb(" + rgb[0] + ", " + rgb[1] + ", " + rgb[2] + ")";
    }

    rgbInterpolation(x) {
        var r = 0
        var g = 0
        var b = 0
        if (x <= 0.5) {
            let a_r = (this.orange[0] - this.green[0]) / 0.5
            r = a_r * x + this.green[0]
            let a_g = (this.orange[1] - this.green[1]) / 0.5
            g = a_g * x + this.green[1]
            let a_b = (this.orange[2] - this.green[2]) / 0.5
            b = a_b * x + this.green[2]
        } else {
            let a_r = (this.red[0] - this.orange[0]) / 0.5
            r = a_r * x + 2 * this.orange[0] - this.red[0]
            let a_g = (this.red[1] - this.orange[1]) / 0.5
            g = a_g * x + 2 * this.orange[1] - this.red[1]
            let a_b = (this.red[2] - this.orange[2]) / 0.5
            b = a_b * x + 2 * this.orange[2] - this.red[2]
        }
        return [Math.floor(r), Math.floor(g), Math.floor(b)]
    }


    applyAirlineNamesFilter(airlineName: string, $event) {

        if (($event["target"]["checked"])) {

            if (!this.airlineFilterNames.includes(airlineName) && airlineName != null) {
                this.airlineFilterNames.push(airlineName);
            }

        } else {
            this.airlineFilterNames.splice(this.airlineFilterNames.indexOf(airlineName), 1);
        }

        this.applyFilters("usingAirLineFilter");
    }

    applyOutboundStopsFilter(outbound: string, $event) {

        if (($event["target"]["checked"])) {

            if (!this.outbound_stops_filters.includes(outbound) && outbound != null) {
                this.outbound_stops_filters.push(outbound);
            }

        } else {
            this.outbound_stops_filters.splice(this.outbound_stops_filters.indexOf(outbound), 1);
        }

        this.applyFilters("usingOutboundStopsFilter");
    }

    applyInboundStopsFilter(inbound: string, $event) {

        if (($event["target"]["checked"])) {

            if (!this.inbound_stops_filters.includes(inbound) && inbound != null) {
                this.inbound_stops_filters.push(inbound);
            }

        } else {
            this.inbound_stops_filters.splice(this.inbound_stops_filters.indexOf(inbound), 1);
        }
        this.applyFilters("usingInboundStopsFilter");
    }

    applyCertificateFilter($event) {

        if (($event["target"]["checked"])) {

            this.applyFilters("usingCertificateFilter");

        } else {
            this.filtersState["usingCertificateFilter"] = false;
            this.applyFilters("removingCertificateFilter");
        }
    }

    applyFilters(filter: string) {

        this.data = cloneDeep(this.originalData);
        this.filtersState[filter] = true;

        if (this.filtersState["usingAirLineFilter"]) {
            if (this.airlineFilterNames.length) {
                this.filter(this.airlineFilterNames, "validatingCarrier");
            }
        }

        if (this.filtersState["usingOutboundStopsFilter"]) {
            if (this.outbound_stops_filters.length) {
                this.filter(this.outbound_stops_filters, "bound_1");
            }
        }

        if (this.filtersState["usingInboundStopsFilter"]) {
            if (this.inbound_stops_filters.length) {
                this.filter(this.inbound_stops_filters, "bound_2");
            }
        }
        if (this.filtersState["usingCertificateFilter"]) {
            for (let recs of Object.keys(this.data.result.recommendations)) {
                for (let index in this.data.result.recommendations[recs]) {
                    if (this.data.result.recommendations[recs][index].flightNeedExchangeDocuments == true) {
                        this.data.result.recommendations[recs][index] = null;
                    }
                }
            }
        }
        this.calcNewPrices();
    }

    filter(filter_array, property) {

        for (let recs of Object.keys(this.data.result.recommendations)) {
            for (let index in this.data.result.recommendations[recs]) {

                if (!this.data.result.recommendations[recs][index]) {
                    continue;
                }
                if (!filter_array.includes(this.data.result.recommendations[recs][index][property])) {
                    this.data.result.recommendations[recs][index] = null;
                } else {
                    this.data.result.recommendations[recs].unshift(this.data.result.recommendations[recs].splice(index, 1)[0]);
                }
            }
        }
    }
    calcNewPrices() {
        this.minp = Math.min.apply(Math, Object.keys(this.data.result.recommendations).map(e => this.data.result.recommendations[e][0]).filter(e => e).map(e => e.price.detailed_price.total));
        this.maxp = Math.max.apply(Math, Object.keys(this.data.result.recommendations).map(e => this.data.result.recommendations[e][0]).filter(e => e).map(e => e.price.detailed_price.total));
    }

}
