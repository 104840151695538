import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { FLIGHT_AMADEUS_MARKUP_B2B_URL, FLIGHT_AMADEUS_AIRLINE_MARKUP_B2B_URL, FLIGHT_AMADEUS_ZONEA_DESTINATION_B2B_URL, FLIGHT_AMADEUS_MARKUP_AGENCY_B2B_URL, FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL } from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";

@Injectable()

export class FlightAmadeusMarkupService {

    constructor(private baseService: BaseService) { }


    getAmadeusMarkup() {
        return this.baseService.tokenGetRequest(FLIGHT_AMADEUS_MARKUP_B2B_URL);
    }

    getAmadeusAirlineMarkup() {
        return this.baseService.tokenGetRequest(FLIGHT_AMADEUS_AIRLINE_MARKUP_B2B_URL);
    }

    getValidatingCarriers() {
        return this.baseService.tokenGetRequest(FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL);
    }

    getZoneADestinations() {
        return this.baseService.tokenGetRequest(FLIGHT_AMADEUS_ZONEA_DESTINATION_B2B_URL);
    }

    getAmadeusMarkupAgency() {
        return this.baseService.tokenGetRequest(FLIGHT_AMADEUS_MARKUP_AGENCY_B2B_URL);
    }

    addAgencyMarkup(markup) {
        return this.baseService.tokenPostRequest(FLIGHT_AMADEUS_MARKUP_AGENCY_B2B_URL, markup);
    }

    updateAgencyMarkup(markup) {
        return this.baseService.tokenPutRequest(FLIGHT_AMADEUS_MARKUP_AGENCY_B2B_URL, markup);
    }

    deleteAgencyMarkup(markupID) {
        return this.baseService.tokenDeleteRequest(FLIGHT_AMADEUS_MARKUP_AGENCY_B2B_URL + "/" + markupID);
    }

}
