
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { of, Observable } from 'rxjs';
import { AIRPORT_AUTOCOMPLETE_API_URL, FLIGHT_AVAILABILITY_API_URL, UrlBuilder, URL_PARAMS, FLIGHT_FARE_CONDITIONS_API_URL, FLIGHT_CALENDAR_URL, FLIGHT_DELIVERY_CONFIRMATION_API_URL } from './urls_b2c';


import { AIRPORT_AUTOCOMPLETE_B2B_URL, FLIGHT_AVAILABILITY_B2B_URL, FLIGHT_FARE_CONDITIONS_B2B_URL, FLIGHT_SCHEDULE_B2B_URL, FLIGHT_PROVIDERS_B2B_URL, FLIGHT_CALENDAR_B2B_URL, FLIGHT_ALL_PROVIDERS_B2B_URL, } from './urls_b2b';
import { BaseService } from './base.service';
import { cloneDeep } from 'lodash';
import { HttpClient } from "@angular/common/http";


@Injectable()
export class FlightService {

  flightBookingAirlineDictionary = null;
  flightBookingAirportDictionnary = null;

  flightBookingRecommendation = null;
  flightBookingArrival = new Array();
  flightBookingDeparture = new Array();

  flightBookingCabinClass: string = null;
  flightBookingTripType: string = null;

  flightBookingAdultNumber: number = null;
  flightBookingChildrenNumber: number = null;
  flightBookingInfantNumber: number = null;
  flightBookingInfantSeatNumber: number = null;
  flightBookingYouthNumber: number = null;
  flightBookingSeniorNumber: number = null;

  flightBookingDomesticFlight: boolean = null;

  flightBookingConfirmationRequest = null;

  flightBookingSearchData = null;
  flightBookingAvailabilityResults: Map<string, any> = new Map<string, any>();

  clientInfos;
  
  adultsInfosArray;
  childrenInfosArray;
  infantsInfosArray;

  infantsSeatInfoArray;
  youthsInfoArray;
  seniorInfoArray;

  onholdbooking;
  corporate;
  canBeBookedOnhold: boolean = false;
  allowMinorSinglePassenger: boolean = false;
  paxNameCharacterLimit: number = 33;
  paxNameCharacterLimitINF: number = 22;
  extraServices = undefined; 
  extraServicesPayload = {};

  zoneB: Map<string, boolean> = new Map<string, boolean>();
  withBaggage = false;
  refundable = false;
  directFlight = false;
  openReturn = false;
  calendar = false;

  extraFieldsArray;
  travellerProfiles;
  bookingFlightProvider = "";
  providersDictionary = [];
  requiredCustomerAddress = false;

  constructor(private baseService: BaseService, private httpClient: HttpClient) { }


  removeFlightPaxData() {
    this.setFlightBookingRecommendation(null);
    this.setFlightBookingDeparture(null);
    //this.setFlightBookingAirportDictionnary(null);
    this.setFlightBookingAdultNumber(null);
    this.setFlightBookingChildrenNumber(null);
    this.setFlightBookingInfantNumber(null);
    //this.setflightBookingTripType(null);
    this.setFlightBookingArrival(null);
    this.setFlightBookingDeparture(null);

    if (this.flightBookingConfirmationRequest && !this.flightBookingConfirmationRequest["onholdbooking"]) {
      this.resetFlightBookingAvailabilityResults();
    }
  }

  getProviders(): Observable<any> {
    return this.baseService.tokenGetRequest(FLIGHT_PROVIDERS_B2B_URL);
  }
  
  getAllFlightProviders():Observable<any>{
	return this.baseService.tokenGetRequest(FLIGHT_ALL_PROVIDERS_B2B_URL)
  }

  removeAllData() {

    /*all except flightBookingSearchData*/

    this.flightBookingAirlineDictionary = null;
    this.flightBookingAirportDictionnary = null;

    this.flightBookingRecommendation = null;
    this.flightBookingArrival = new Array();
    this.flightBookingDeparture = new Array();

    this.flightBookingCabinClass = null;
    this.flightBookingTripType = null;

    this.flightBookingAdultNumber = null;
    this.flightBookingChildrenNumber = null;
    this.flightBookingInfantNumber = null;
    this.flightBookingInfantSeatNumber = null;
    this.flightBookingYouthNumber = null;
    this.flightBookingSeniorNumber = null;

    this.flightBookingDomesticFlight = null;

    this.flightBookingConfirmationRequest = null;

    this.zoneB.clear();
    this.flightBookingAvailabilityResults.clear();
  }

  getFlightSearchParams(key) {
    return this.baseService.getData(key);
  }

  setFlightSearchParams(key, value) {
    this.baseService.saveData(key, value);
  }

  deleteFlightSearchParams(key) {
    this.baseService.deleteData(key);
  }

  getFlightBookingAirlineDictionary() {
    return this.flightBookingAirlineDictionary;
  }

  setFlightBookingAirlineDictionary(airlineDictionary) {
    this.flightBookingAirlineDictionary = cloneDeep(airlineDictionary);
  }

  getFlightBookingRecommendation() {
    return this.flightBookingRecommendation;
  }

  setFlightBookingRecommendation(recommendation) {
    this.flightBookingRecommendation = cloneDeep(recommendation);
  }

  getFlightBookingArrival() {
    return this.flightBookingArrival;
  }

  setFlightBookingArrival(arrival) {
    this.flightBookingArrival = cloneDeep(arrival);
  }

  getFlightBookingDeparture() {
    return this.flightBookingDeparture;
  }

  setFlightBookingDeparture(departure) {
    this.flightBookingDeparture = cloneDeep(departure);
  }

  getFlightBookingAirportDictionnary() {
    return this.flightBookingAirportDictionnary;
  }

  setFlightBookingAirportDictionnary(airportDictionary) {
    this.flightBookingAirportDictionnary = cloneDeep(airportDictionary);
  }

  getFlightBookingAdultNumber() {
    return this.flightBookingAdultNumber;
  }

  setFlightBookingAdultNumber(adultNumber) {
    this.flightBookingAdultNumber = adultNumber;
  }

  getFlightBookingChildrenNumber() {
    return this.flightBookingChildrenNumber;
  }

  setFlightBookingChildrenNumber(childrenNumber) {
    this.flightBookingChildrenNumber = childrenNumber;
  }

  getFlightBookingInfantNumber() {
    return this.flightBookingInfantNumber;
  }

  setFlightBookingInfantNumber(infantNumber) {
    this.flightBookingInfantNumber = infantNumber;
  }

  getflightBookingCabinClass() {
    return this.flightBookingCabinClass;
  }

  setflightBookingCabinClass(cabinClass) {
    this.flightBookingCabinClass = cabinClass;
  }

  getflightBookingTripType() {
    return this.flightBookingTripType;
  }

  setflightBookingTripType(tripType) {
    this.flightBookingTripType = tripType;
  }

  getflightBookingDomesticFlight() {
    return this.flightBookingDomesticFlight;
  }

  setflightBookingDomesticFlight(domesticFlight) {
    this.flightBookingDomesticFlight = domesticFlight;
  }

  getflightBookingConfirmationRequest() {
    return this.flightBookingConfirmationRequest;
  }

  setflightBookingConfirmationRequest(request) {
    this.flightBookingConfirmationRequest = cloneDeep(request);
  }

  setExtrasServicePayload(payload) {
    this.extraServicesPayload = cloneDeep(payload);
  }

  setFlightBookingInfantSeatNumber(infantSeatNumber) {
    this.flightBookingInfantSeatNumber = infantSeatNumber;
  }

  getFlightBookingInfantSeatNumber() {
    return this.flightBookingInfantSeatNumber;
  }

  setFlightBookingYouthNumber(youthNumber) {
    this.flightBookingYouthNumber = youthNumber;
  }

  getFlightBookingYouthNumber() {
    return this.flightBookingYouthNumber;
  }

  setFlightBookingSeniorNumber(seniorNumber) {
    this.flightBookingSeniorNumber = seniorNumber;
  }

  getFlightBookingSeniorNumber() {
    return this.flightBookingSeniorNumber;
  }

  setFlightBookingAvailabilityResults(results, provider: string) {
    this.flightBookingAvailabilityResults.set(provider, cloneDeep(results));
  }

  resetFlightBookingAvailabilityResults() {
    this.flightBookingAvailabilityResults.clear();
  }

  getFlightBookingAvailabilityResults(provider: string) {
    return this.flightBookingAvailabilityResults.get(provider);
  }

  getFlightBookingSearchData() {
    return this.flightBookingSearchData;
  }

  getFlightNeedExchangeDocuments(provider){
    const hasProvider = this.flightBookingAvailabilityResults.has(provider);    
    return hasProvider ? this.flightBookingAvailabilityResults.get(provider).flightNeedExchangeDocuments : null;
  }

  setFlightBookingSearchData(data) {
    this.openReturn = data ["openReturn"];
    this.directFlight = data ["directFlight"];
    this.withBaggage = data ["withBaggage"];
    this.refundable = data ["refundable"];
    this.flightBookingSearchData = cloneDeep(data);
  }

  public getExtraServices() {
    this.extraServices = [];
    for (let key in this.extraServicesPayload) {
      let value = this.extraServicesPayload[key];
      for (let i=0; i<value.length; i++){
        this.extraServices.push(value[i]);
      }
      
    }
    return this.extraServices;
  }

  public getExtraServicesTotal(extras){
    let total = 0;
    for (let key in this.extraServicesPayload) {
      let value = this.extraServicesPayload[key];
      for (let i=0; i<value.length; i++){
        if (value[i].options.length > 0){
          for (let j=0; j<value[i].options.length; j++){
            if (value[i].options[j].amount != undefined) {
              total = total + Number(value[i].options[j].amount);
            }
          }
        }        
      }
      
    }
    if (total == 0) {
      if (this.flightBookingConfirmationRequest!=null){
        for (let i=0; i<this.flightBookingConfirmationRequest.extraservices.lenght; i++) {
          let value = this.flightBookingConfirmationRequest.extraservices[i];
          if (value[i].options.length > 0){
            for (let j=0; j<value[i].options.length; j++){
              if (value[i].options[j].amount != undefined) {
                total = total + Number(value[i].options[j].amount);
              }
            }
          }  
        } 
      }
                 
    }
    return total;
  }


  public getAirports(request) {

    if (request === '') {
      return of([]);
    }

    let correctUrl;
    (this.baseService.b2bUserType == "B2B") ? correctUrl = AIRPORT_AUTOCOMPLETE_B2B_URL : correctUrl = AIRPORT_AUTOCOMPLETE_API_URL;

    const urlBuilder = new UrlBuilder(correctUrl).addParam(URL_PARAMS.TERM, request);

    return this.baseService.tokenGetRequest(urlBuilder.getUrl())
      .map((response: Response) => response['predictions']);

  }

  public getAvailability(request, handleError=true) {

    const tripType = request['tripType'];

    return this.getAvailabilityCode(request, tripType, handleError)

  }


  getAvailabilityCode(request, tripType, handleError=true): Observable<any> {

    let urlBuilder;
    (this.baseService.b2bUserType == "B2B") ? urlBuilder = new UrlBuilder(FLIGHT_AVAILABILITY_B2B_URL) : urlBuilder = new UrlBuilder(FLIGHT_AVAILABILITY_API_URL);

    // TODO : find a way to add automatically all paremeters from the array into the url
    for (let i = 0; i < request['bounds']; i++) {
      urlBuilder.addParam('departureDate_' + (i + 1), request['departureDate_' + (i + 1)]);
      urlBuilder.addParam('destination_' + (i + 1), request['destination_' + (i + 1)]);
      urlBuilder.addParam('origin_' + (i + 1), request['origin_' + (i + 1)]);
      if(request['cabinClass_' + (i + 1)]){
        urlBuilder.addParam('cabinClass_' + (i + 1), request['cabinClass_' + (i + 1)]);
      }
    }
    
    if(request['tripType'] != "rt"){
      request['openReturn'] = false;
    }    

    urlBuilder.addParam('requestedMultiCity', request['requestedMultiCity'])
      .addParam('requestedFlightNonStop', request['requestedFlightNonStop'])
      .addParam('range', request['range'])
      .addParam('flexible', request['flexible'])
    
      .addParam('nrOfAdult', request['nrOfAdult'])
      .addParam('nrOfChild', request['nrOfChild'])
      .addParam('nrOfInfant', request['nrOfInfant'])
      .addParam('nrOfInfantWithSeat', request['nrOfInfantWithSeat'])
      .addParam('nrOfYouth', request['nrOfYouth'])
      .addParam('nrOfSenior', request['nrOfSenior'])
      .addParam('tripType', request['tripType'])
      .addParam('cabinClass', request['cabinClass'])
      .addParam('withBaggage', request['withBaggage'])
      .addParam('refundable', request['refundable'])
      .addParam('directFlight', request['directFlight'])
      .addParam('openReturn', request['openReturn'])
      .addParam('bounds', request['bounds'])
	  .addParam('provider_id', request['provider_id'])
      .addParam('provider_code', request['provider_code']);

    if (tripType === 'rt') {
      urlBuilder.addParam('departureDate_2', request['departureDate_2'])
        .addParam('destination_2', request['origin_1'])
        .addParam('origin_2', request['destination_1']);
    }   

    return this.baseService.tokenGetRequest(urlBuilder.getUrl(), handleError);

  }
  public getFlightCalendar(request, handleError): Observable<any> {

    let urlBuilder;
    (this.baseService.b2bUserType == "B2B") ? urlBuilder = new UrlBuilder(FLIGHT_CALENDAR_B2B_URL) : urlBuilder = new UrlBuilder(FLIGHT_CALENDAR_URL);
	urlBuilder.addParam('range', request['range']);
	
    // TODO : find a way to add automatically all paremeters from the array into the url
    for (let i = 0; i < request['bounds']; i++) {
      urlBuilder.addParam('departureDate_' + (i + 1), request['departureDate_' + (i + 1)]);
      urlBuilder.addParam('destination_' + (i + 1), request['destination_' + (i + 1)]);
      urlBuilder.addParam('origin_' + (i + 1), request['origin_' + (i + 1)]);
      if(request['cabinClass_' + (i + 1)]){
        urlBuilder.addParam('cabinClass_' + (i + 1), request['cabinClass_' + (i + 1)]);
      }
    }
    
    if(request['tripType'] != "rt"){
      request['openReturn'] = false;
    }    

    urlBuilder.addParam('requestedMultiCity', request['requestedMultiCity'])
      .addParam('requestedFlightNonStop', request['requestedFlightNonStop'])
      .addParam('flexible', request['flexible'])
    
      .addParam('nrOfAdult', request['nrOfAdult'])
      .addParam('nrOfChild', request['nrOfChild'])
      .addParam('nrOfInfant', request['nrOfInfant'])
      .addParam('nrOfInfantWithSeat', request['nrOfInfantWithSeat'])
      .addParam('nrOfYouth', request['nrOfYouth'])
      .addParam('nrOfSenior', request['nrOfSenior'])
      .addParam('tripType', request['tripType'])
      .addParam('cabinClass', request['cabinClass'])
      .addParam('withBaggage', request['withBaggage'])
      .addParam('refundable', request['refundable'])
      .addParam('directFlight', request['directFlight'])
      .addParam('openReturn', request['openReturn'])
      .addParam('bounds', request['bounds'])
      .addParam('provider_id', request['provider_id'])
      .addParam('provider_code', request['provider_code']);




    return this.baseService.tokenGetRequest(urlBuilder.getUrl(), handleError);

  }

  public getFlightConditions(request) {

    let correctUrl;

    (this.baseService.b2bUserType == "B2B") ? correctUrl = FLIGHT_FARE_CONDITIONS_B2B_URL : correctUrl = FLIGHT_FARE_CONDITIONS_API_URL;
    return this.baseService.tokenPostRequest(correctUrl, request);
  }

  getFlightOffers() {
    return this.httpClient.get("../../../assets/testing data/flightsOffers.json");
  }

  isZoneB(provider: any): boolean {
    if(!this.zoneB.has(provider.id))
    {
      return false;
    }

    return this.zoneB.get(provider.id);
  }

  setIsZoneB(provider: any, value: boolean) {
    this.zoneB.set(provider.id, value);
  }

  public setBooking() { }

  public getExtraFieldsArray(){
    return this.extraFieldsArray;
  }

  public setExtraFieldsArray(extraFieldsArray){
    return this.extraFieldsArray = extraFieldsArray;
  }
  public getTravellerProfilesArray(){
    return this.travellerProfiles;
  }

  public setTravellerProfilesArray(travellerProfiles){
    return this.travellerProfiles = travellerProfiles;
  }

  getFlightSchedule(origin, destination, validatingCarrier, directFlight) {

    const urlBuilder = new UrlBuilder(FLIGHT_SCHEDULE_B2B_URL)
        .addParam("origin", origin)
        .addParam("destination", destination)

    if (validatingCarrier) {
      urlBuilder.addParam("validatingCarrier", validatingCarrier);
    }
    if (directFlight) {
      urlBuilder.addParam("directFlight", directFlight);
    }

    return this.baseService.tokenGetRequest(urlBuilder.getUrl());
  }
  confirmFlightDeliveryBooking(data){
	const urlBuilder = new UrlBuilder(FLIGHT_DELIVERY_CONFIRMATION_API_URL)
	return this.baseService.tokenPostRequest(urlBuilder.getUrl(),data);
  }
    getBookingFlightProvider(){
        return this.bookingFlightProvider;
    }

    setBookingFlightProvider(provider:string){
        this.bookingFlightProvider = provider;
	}

    getFlightProviders(){
        return this.providersDictionary;
    }
    setFlightProviders(providersDictionary){
        this.providersDictionary = providersDictionary;
    }
}
