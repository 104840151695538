<div class="container loader-child-bg">
  <div class="row">
    <div class="col-md-12 col-sm-12 spinner-text-container">
      <div class="col-md-12 col-sm-12 loader-child-spinner">
        <i class="fa fa-fw fa-spinner loading-spinner"></i>
      </div>
      <div class="col-md-12 col-sm-12 loader-child-text">
        <h3>{{loaderChildMessage|translate}}</h3>
      </div>
    </div>
  </div>
</div>