import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { LoginService } from "../../services/login.service";
import { BaseService } from "../../services/base.service";
import { ShareVars } from '@app/shared/services/share.vars';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from '../../validators/confirmed.validator';

@Component({
  selector: 'app-b2c-reset-password-page',
  templateUrl: './b2c-reset-password-page.component.html',
  styleUrls: ['./b2c-reset-password-page.component.scss']
})
export class B2cResetPasswordPageComponent implements OnInit {

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute,private translateService: TranslateService,private navigationService: NavigationService, private loginService: LoginService, private baseService: BaseService) {
    this.form = fb.group({
      password: ['', [Validators.required]],
      confirm_password: ['', [Validators.required]]
    }, { 
      validator: ConfirmedValidator('password', 'confirm_password')
    })
  }

  form: FormGroup = new FormGroup({});
  token: string;
  sucessResetPWD: boolean = false;

  ngOnInit() {
    ShareVars.isBusy = false;
    this.activatedRoute.params.subscribe(
      data => this.token = data['token'],
      error => this.navigationService.goToHomePage()
    );
  }

  resetB2cAccount(){
    this.baseService.setToken(this.token);
    this.loginService.resetB2CPassword({new_password: this.form.controls['password'].value}).subscribe(
      data => {
          this.navigationService.goToHomePage();
          if (data.error) {
            this.sucessResetPWD = false;
            this.baseService.showNotyf('error', data.description);
          } else {
            this.sucessResetPWD = true;
            this.baseService.showNotyf('success', 'FORGET_PWD_SUCCESS');
          }  
      }
    );
  }

  goToLoginPage() {
    this.navigationService.goToB2BLogin();
  }

  togglePassword(elm) {
    let pass = document.querySelector(`#${elm}`) as HTMLInputElement;
    if (pass.type === 'password') {
      pass.type = 'text'
      document.querySelector(`#pass-icon_${elm}`).classList.add('fa-eye', 'fa-eye-slash');
    } else {
      pass.type = 'password'
      document.querySelector(`#pass-icon_${elm}`).classList.replace('fa-eye-slash', 'fa-eye');
    }
  }

  get f(){
    return this.form.controls;
  }
   
  isArabic() {
    return this.baseService.isArabic();
  }

}
