import { Injectable } from "@angular/core";
import {
	POINT_OF_SALE_API_URL,
	TOWNS_API_URL,
	DEPARTEMENTS_API_URL,
	COUNTRIES_API_URL,
	STATIC_API_URL,
	API_URL,
	DEPARTMENTS_OF_SALE_API_URL,
	POINT_OF_SALE_IN_DEPARTMENT_API_URL,
} from "./urls_b2c";
import { BASE_API } from "./urls_b2b";
import { BaseService } from "./base.service";

@Injectable()
export class LocalizationService {
	constructor(private baseService: BaseService) {}

	getDepartements() {
		let departementUrl = API_URL;
		if (this.baseService.b2bUserType == "B2B") {
			departementUrl = BASE_API;
		}

		departementUrl = departementUrl + DEPARTEMENTS_API_URL;
		return this.baseService.tokenGetRequest(departementUrl);
	}



	getDepartementsOfSale() {
		return this.baseService.tokenGetRequest(DEPARTMENTS_OF_SALE_API_URL);
	}

	getAgenciesOfSale(departementId){
		return this.baseService.tokenGetRequest(POINT_OF_SALE_IN_DEPARTMENT_API_URL + "?department_id="+departementId);

	}
	getTowns(departementId) {
		return this.baseService.tokenGetRequest(
			TOWNS_API_URL + "?department_id=" + departementId
		);
	}

	getCountries() {
		return this.baseService.tokenGetRequest(
			STATIC_API_URL + COUNTRIES_API_URL
		);
	}

	getPointsOfSale(townId) {
		return this.baseService.tokenGetRequest(
			POINT_OF_SALE_API_URL + "?town_id=" + townId
		);
	}
}
