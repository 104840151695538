import { Injectable } from '@angular/core';
import { SIGNUP_API_URL } from '@app/shared/services/urls_b2c';
import { AGENCY_REGISTER_B2B } from '@app/shared/services/urls_b2b';
import { BaseService } from '@app/shared/services/base.service';

@Injectable()
export class SignUpService {

    constructor(private baseService: BaseService) { }

    register(user) {

        return this.baseService.tokenPostRequest(SIGNUP_API_URL, user);
    }

    registerAgencyB2B(agency) {
        return this.baseService.tokenPostRequest(AGENCY_REGISTER_B2B, agency);
    }


}
