/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./flight-edit-ticket.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./flight-edit-ticket.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../shared/services/flight-manage-bookings.service";
import * as i5 from "../../../shared/services/navigation.service";
import * as i6 from "../../../shared/services/base.service";
import * as i7 from "../../../shared/services/flight.service";
import * as i8 from "@ngx-translate/core";
var styles_FlightEditTicketComponent = [i0.styles];
var RenderType_FlightEditTicketComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FlightEditTicketComponent, data: {} });
export { RenderType_FlightEditTicketComponent as RenderType_FlightEditTicketComponent };
export function View_FlightEditTicketComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_FlightEditTicketComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-flight-edit-ticket", [], null, null, null, View_FlightEditTicketComponent_0, RenderType_FlightEditTicketComponent)), i1.ɵdid(1, 114688, null, 0, i2.FlightEditTicketComponent, [i3.ActivatedRoute, i4.FlightManageBookingsService, i5.NavigationService, i6.BaseService, i3.Router, i7.FlightService, i8.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FlightEditTicketComponentNgFactory = i1.ɵccf("app-flight-edit-ticket", i2.FlightEditTicketComponent, View_FlightEditTicketComponent_Host_0, {}, {}, []);
export { FlightEditTicketComponentNgFactory as FlightEditTicketComponentNgFactory };
