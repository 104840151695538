import * as tslib_1 from "tslib";
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { BaseService } from './base.service';
var I18N_VALUES = {
    'es': {
        weekdays: ['L', 'M', 'M', 'J', 'V', 'S', 'D'],
        months: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    },
    'en': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Avr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    'fr': {
        weekdays: ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'],
        months: ['Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aou', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
    'ar': {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        months: ['Jan', 'Feb', 'Mar', 'Avr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    },
};
// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
var I18n = /** @class */ (function () {
    function I18n() {
        this.language = 'es';
    }
    return I18n;
}());
export { I18n };
var CustomDatepickerI18nService = /** @class */ (function (_super) {
    tslib_1.__extends(CustomDatepickerI18nService, _super);
    function CustomDatepickerI18nService(_i18n, baseService) {
        var _this = _super.call(this) || this;
        _this._i18n = _i18n;
        _this.baseService = baseService;
        _this._i18n.language = _this.baseService.getLanguage();
        return _this;
    }
    CustomDatepickerI18nService.prototype.getWeekdayShortName = function (weekday) {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    };
    CustomDatepickerI18nService.prototype.getMonthShortName = function (month) {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    };
    CustomDatepickerI18nService.prototype.getMonthFullName = function (month) {
        return this.getMonthShortName(month);
    };
    CustomDatepickerI18nService.prototype.getDayAriaLabel = function (date) {
        return date.day + "-" + date.month + "-" + date.year;
    };
    return CustomDatepickerI18nService;
}(NgbDatepickerI18n));
export { CustomDatepickerI18nService };
