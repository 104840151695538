import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { BaseService } from '../../shared/services/base.service';
import { NavigationService } from '../../shared/services/navigation.service';
import { ShareVars } from '../../shared/services/share.vars';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()

export class EnableB2CGuard implements CanActivate {

    constructor(private baseService: BaseService, private navigationService: NavigationService) {
    }

    canActivate(): Observable<boolean> {

        ShareVars.isBusy = true;

        return this.baseService.getB2CState().pipe(
            map(data => {

                const url = window.location.href;

                if (url.indexOf("iframe=") != -1) {

                    const iframeValue = url.substring((url.indexOf("=") + 1), url.indexOf("&"));
                   
                    if (iframeValue == "true") {
                        // TODO hide footer and header
                    }
                }

                if (url.indexOf("token=") != -1) {
                    const tokenValue = url.substring(url.lastIndexOf("=") + 1);
                    this.intiCookiesB2B(tokenValue);
                    return;
                }

                

                if (data || this.baseService.isB2BUserCookies()) {

                    ShareVars.isBusy = false;
                    return true;

                } else {
                    // ShareVars.isBusy = false;
                    this.navigationService.goToB2BLogin();
                    return false;
                }
            })
        );
    }

    intiCookiesB2B(tokenValue: string) {
        this.baseService.setToken(tokenValue);
        this.baseService.setUserTypeCookies("B2B");
        window.location.href = "/home";
    }

}