import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { PACKAGE_BOOKING_CONFIRMATION_API_URL } from '@app/shared/services/urls_b2c';
import { PACKAGES_CONFIRATION_B2B_URL, PACKAGES_CONFIRATION_ONHOLD_B2B_URL, PACKAGES_CONFIRATION_CANCEL_B2B_URL } from '@app/shared/services/urls_b2b';


@Injectable()
export class PackageBookingConfirmationService {

  confirmationData = null;

  constructor(private baseService: BaseService) {
  }

  setPackageBookingConfirmationData(data) {
    this.confirmationData = data;
  }

  getPackageBookingConfirmationData() {
    return this.confirmationData;
  }

  // confirm reservation
  confirmPackageBooking(request) {

    let correctUrl;
    (this.baseService.b2bUserType == "B2B") ? correctUrl = PACKAGES_CONFIRATION_B2B_URL : correctUrl = PACKAGE_BOOKING_CONFIRMATION_API_URL;

    return this.baseService.tokenPostRequest(correctUrl, request);
  }

  confirmPackageBookingOnhold(id) {
    return this.baseService.tokenGetRequest(PACKAGES_CONFIRATION_ONHOLD_B2B_URL + id);
  }

  cancelPackageBooking(id) {
    return this.baseService.tokenGetRequest(PACKAGES_CONFIRATION_CANCEL_B2B_URL + id);
  }

}
