export class packageManageBookingsSaves {

    useService: boolean = false;
    firstDay;
    lastDay;
    userB2B;
    isAdmin;

    /* bookings display */
    packages = {
        packages: null,
        originalPackages: null,
        predictions: null,
        countries: null,
        cities: null,
        packagesList: null
    }

    /* date */
    date = {
        dateType: null,
        dateTypeValue: null
    }

    /* mode */
    mode = {
        modeType: null,
        modeTypeValue: null
    }

    /* agencies */
    agenciesData = {
        agencies: null,
        agenciesDPD: null,
        selectedAgencyValue: null,
        selectedAgency: null
    };

    /* platform */
    platformsData = {
        platformsList: null,
        selectedPlatformValue: null,
        selectedPlatform: null,
        platformDPD: null,
    };

    /* agents */
    agentsData = {
        agents: null,
        agentsDPD: null,
        selectedAgentValue: null,
        selectedAgent: null,
    };

    /* status */
    statusData = {
        selectedStatus: null,
        selectedStatusValue: null
    };

    /* input text */
    customerName;
    paxName;
    reference;

    constructor() { }
}