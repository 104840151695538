<app-modal title="{{'SIGNUP'|translate}}" #signUpModal [showModal]="errorModalShowModal && showSignup" modalWidth="70" (closeModalFrom2NDParent)="closeModal()">
    <app-signup>
    </app-signup>
</app-modal>
<app-modal title="{{'FORGOT_PW'|translate}}" #forgetPasswordModal [showModal]="errorModalShowModal && showForgetPassword" modalWidth="70" (closeModalFrom2NDParent)="closeModal()">
    <app-forget-password [type]="'b2c'">
    </app-forget-password>
</app-modal>
<div class="full-modal animated fadeIn" *ngIf="errorModalShowModal && showLogin">
    <div class="ab-modal-box" [style.width.%]="70">
        <div class="flight-details-header">
            <button type="button" id="modal_close_button" class="close" (click)="closeModal()">
                <span aria-hidden="true">
                    <i class="fa fa-times"></i>
                </span>
            </button>
            <h4 style="text-transform: uppercase">{{errorTitle|translate}}</h4>
            <div class="clearfix"></div>
        </div>
        <div class="modal-overflow">
            <div class="col-md-12 col-sm-12" style="text-align: center; font-size: 17px"
			 [ngStyle]="showHome ? {'padding' : '30px 0px'} : {'padding': '30px 0px 51px 0px'}">
                {{errorMessage|translate}}
            </div>

            <div *ngIf="errorTitle==='PAYMENT_ERROR'" class="col-md-12 col-sm-12" style="text-align: center; font-size: 17px" >
                <p> En cas de problème, appelez <img src="/assets/common/images/3020.png" style="width: 50px;"></p>
            </div>

            <div class="col-xs-12 col-sm-12 login-form text-center" style="padding: 0;" *ngIf="showHome">
                <button (click)="goToHomePage()">
                    {{"HOME"|translate}}
                </button>
            </div>

            <div class="col-md-2 col-sm-2">
            </div>

            <div class="col-md-8 col-sm-8" *ngIf="errorStatus==401 && userType!='B2B' ">
                <app-login [showSignUp]="true" (goToSignUp)="showSignUp()" (goToForgetPassword)="showForgetPasswordCall()"></app-login>
            </div>

            <!-- <div class="col-md-8 col-sm-8" *ngIf="errorStatus==401">
                <app-login [showSignUp]="true"></app-login>
            </div> -->
        </div>
    </div>
</div>
