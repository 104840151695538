import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {
    HOTEL_MANAGE_BOOKINGS_HOTELS_B2B_URL, HOTEL_MANAGE_BOOKINGS_VOUCHER_B2B_URL, AGENT_B2B_URL,
    AGENCY_AGENCIES_B2B, AGENCY_PLATFORMS_B2B, AGENCY_PROVIDERS_B2B, HOTEL_REFRESH_B2B_URL, HOTEL_CANCELLATION_CHARGES_B2B_URL, HOTEL_CANCEL_B2B_URL
} from "./urls_b2b";
import { UrlBuilder } from "./urls_b2c";
import { hotelManageBookingsSaves } from './hotel-manage-bookings-saves';

@Injectable()

export class HotelManageBookingsService {

    searchSaves = new hotelManageBookingsSaves();

    constructor(private baseService: BaseService) {
    }

    getAgentsList() {
        return this.baseService.tokenGetRequest(AGENT_B2B_URL);
    }

    getAgenciesList(term? : string) {
		term = term ? "?term="+term : "";
        return this.baseService.tokenGetRequest(AGENCY_AGENCIES_B2B +term);
    }

    getPlatformsList() {
        return this.baseService.tokenGetRequest(AGENCY_PLATFORMS_B2B + "?type=HOTEL");
    }

    getProvidersList() {
        return this.baseService.tokenGetRequest(AGENCY_PROVIDERS_B2B + "?product=HOTEL");
    }

    getHotelBookings(firstDay, lastDay, dateType, modeType, status, agent, customerName, paxName, reference, agencyID, platformID, provider, per_page, page) {

        const urlBuilder = new UrlBuilder(HOTEL_MANAGE_BOOKINGS_HOTELS_B2B_URL)
            .addParam("firstDay", firstDay)
            .addParam("lastDay", lastDay)
            .addParam("date_type", dateType);

        if (modeType) {
            urlBuilder.addParam("type", modeType);
        }

        if (status) {
            urlBuilder.addParam("status", status);
        }

        if (agent) {
            urlBuilder.addParam("agentUsername", agent);
        }

        if (customerName) {
            urlBuilder.addParam("customer", customerName);
        }

        if (paxName) {
            urlBuilder.addParam("pax", paxName);
        }

        if (reference) {
            urlBuilder.addParam("reference", reference);
        }

        if (agencyID) {
            urlBuilder.addParam("agency_id", agencyID);
        }

        if (platformID) {
            urlBuilder.addParam("platform_id", platformID);
        }

        if(provider){
            urlBuilder.addParam("provider_id", provider.id);
        }

		if(per_page){
			urlBuilder.addParam("per_page", per_page);
		}
		
		if(page){
			urlBuilder.addParam("page", page);
		}
		return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }

    getVoucher(ref) {
        return this.baseService.tokenGetRequest(HOTEL_MANAGE_BOOKINGS_VOUCHER_B2B_URL + ref);
    }

    confirmBooking(provider, internalReference) {
        return this.baseService.tokenPostRequest(HOTEL_REFRESH_B2B_URL + provider + '&internal_reference=' + internalReference, {});
    }

    getCancellationCharges(reference, internalReference) {
        return this.baseService.tokenGetRequest(HOTEL_CANCELLATION_CHARGES_B2B_URL  + reference + '&internal_reference=' + internalReference);
    }

    refundBooking(provider, reference, internalReference) {
        return this.baseService.tokenDeleteRequest(HOTEL_CANCEL_B2B_URL + provider + '&booking_reference=' + reference + '&internal_reference=' + internalReference)
    }

}
