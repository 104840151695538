<div class="full-modal animated fadeIn" *ngIf="showModal" style="overflow: hidden">
    <div class="ab-modal-box App-Model-Hotel-Photos" [style.height.%]="modalHeight" [style.width.%]="modalWidth">
        <div class="header-top flight-details-header">
            <div>
                <button type="button" id="modal_close_button" class="close" (click)="closeModal()"
                    style="background: none; color: white;">
                    <span aria-hidden="true">
                        <i class="fa fa-times"></i>
                    </span>
                </button>
                <h4>{{title}}</h4>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="modal-overflow">
            <ng-content></ng-content>
        </div>
        <div class="flight-details-footer" style="height:60px;" *ngIf="actionButton!==''">
            <button type="submit" class="search-button btn transition-effect" style="margin-right:10px; float:right;"
                (click)="sendEvent()">
                {{ actionButton|translate }}
            </button>
        </div>
        <div class="col-md-11 col-sm-12 col-xs-12 book-btn-container" *ngIf="bookPackage">
            <button class="book-button" (click)="bookPackageAction()"
                [ngClass]="{'disabled-button': disableButton}">{{"BOOK"|translate}}</button>
        </div>
    </div>
</div>
