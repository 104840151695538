import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { ShareVars } from './share.vars';
import { CHARTER_CANCEL_BOOKING, CHARTER_CONFIRM_BOOKING, CHARTER_GET_BOOKINGS } from './urls_b2b';

@Injectable()

export class CharterManageService {
    token;
    headers;
    currentBooking;


    constructor(private baseService: BaseService) {
        
    }    

    getBookings() {
        return this.baseService.tokenGetRequest(CHARTER_GET_BOOKINGS);
    }

    confirmBooking(id){
        return this.baseService.tokenGetRequest(CHARTER_CONFIRM_BOOKING + '/' + id);
    }

    cancelBooking(id){
        return this.baseService.tokenGetRequest(CHARTER_CANCEL_BOOKING + '/' + id);
    }
}
