import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { LoginService } from "../../services/login.service";
import { BaseService } from "../../services/base.service";
import { ShareVars } from '@app/shared/services/share.vars';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from '../../validators/confirmed.validator';
var B2cResetPasswordPageComponent = /** @class */ (function () {
    function B2cResetPasswordPageComponent(fb, activatedRoute, translateService, navigationService, loginService, baseService) {
        this.fb = fb;
        this.activatedRoute = activatedRoute;
        this.translateService = translateService;
        this.navigationService = navigationService;
        this.loginService = loginService;
        this.baseService = baseService;
        this.form = new FormGroup({});
        this.sucessResetPWD = false;
        this.form = fb.group({
            password: ['', [Validators.required]],
            confirm_password: ['', [Validators.required]]
        }, {
            validator: ConfirmedValidator('password', 'confirm_password')
        });
    }
    B2cResetPasswordPageComponent.prototype.ngOnInit = function () {
        var _this = this;
        ShareVars.isBusy = false;
        this.activatedRoute.params.subscribe(function (data) { return _this.token = data['token']; }, function (error) { return _this.navigationService.goToHomePage(); });
    };
    B2cResetPasswordPageComponent.prototype.resetB2cAccount = function () {
        var _this = this;
        this.baseService.setToken(this.token);
        this.loginService.resetB2CPassword({ new_password: this.form.controls['password'].value }).subscribe(function (data) {
            _this.navigationService.goToHomePage();
            if (data.error) {
                _this.sucessResetPWD = false;
                _this.baseService.showNotyf('error', data.description);
            }
            else {
                _this.sucessResetPWD = true;
                _this.baseService.showNotyf('success', 'FORGET_PWD_SUCCESS');
            }
        });
    };
    B2cResetPasswordPageComponent.prototype.goToLoginPage = function () {
        this.navigationService.goToB2BLogin();
    };
    B2cResetPasswordPageComponent.prototype.togglePassword = function (elm) {
        var pass = document.querySelector("#" + elm);
        if (pass.type === 'password') {
            pass.type = 'text';
            document.querySelector("#pass-icon_" + elm).classList.add('fa-eye', 'fa-eye-slash');
        }
        else {
            pass.type = 'password';
            document.querySelector("#pass-icon_" + elm).classList.replace('fa-eye-slash', 'fa-eye');
        }
    };
    Object.defineProperty(B2cResetPasswordPageComponent.prototype, "f", {
        get: function () {
            return this.form.controls;
        },
        enumerable: true,
        configurable: true
    });
    B2cResetPasswordPageComponent.prototype.isArabic = function () {
        return this.baseService.isArabic();
    };
    return B2cResetPasswordPageComponent;
}());
export { B2cResetPasswordPageComponent };
