
import { Injectable } from '@angular/core';
import { DISTINATIONS_API_URL } from './urls_b2c';
import { PACKAGES_DEPARTURES_B2B_URL, PACKAGES_DISTINATIONS_B2B_URL } from './urls_b2b';
import { BaseService } from './base.service';


@Injectable()
export class DestinationsService {

  constructor(private baseService: BaseService) {
  }

  // destinations
  getDestinations() {
    let correctUrl;
    (this.baseService.b2bUserType == "B2B") ? correctUrl = PACKAGES_DISTINATIONS_B2B_URL : correctUrl = DISTINATIONS_API_URL;
    return this.baseService.tokenGetRequest(correctUrl);
  }

  getDepartures() {
    return this.baseService.tokenGetRequest(PACKAGES_DEPARTURES_B2B_URL);
  }


}
