import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import {
    FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL, AGENT_B2B_URL, AGENCY_PLATFORMS_B2B, AGENCY_AGENCIES_B2B,
    FLIGHT_MANAGE_BOOKINGS_FLIGHTS_B2B_URL, FLIGHT_PNR_B2B_URL, FLIGHT_GET_PNR_B2B_URL, FLIGHT_REFRESH_B2B_URL, FLIGHT_CANCEL_ETICKET_B2B_ADMIN_URL,
    FLIGHT_CANCEL_ETICKET_B2B_URL, FLIGHT_TRANSFERT_ETICKET_B2B_URL, FLIGHT_CONFIRM_ONHOLD_ETICKET_B2B_URL, FLIGHT_SYNCH_ETICKET_B2B_URL, FLIGHT_CRYPTIC_B2B_URL, AGENCY_PROVIDERS_B2B, AGENCY_FLIGHT_PROVIDERS_B2B_URL
} from "./urls_b2b";
import { UrlBuilder, FLIGHT_ETICKET_PNR_URL } from "./urls_b2c";
import { flightManageBookingsSaves } from './flight-manage-bookings-saves';

@Injectable()

export class FlightManageBookingsService {

    searchSaves = new flightManageBookingsSaves();

    constructor(private baseService: BaseService) {
    }


    getValidatingCarriers() {
        return this.baseService.tokenGetRequest(FLIGHT_MANAGE_BOOKINGS_VALIDATING_CARRIERS_B2B_URL);
    }

    getAgentsList() {
        return this.baseService.tokenGetRequest(AGENT_B2B_URL);
    }

    getAgenciesList(term? : string) {
		term = term ? "?term="+term : "";
        return this.baseService.tokenGetRequest(AGENCY_AGENCIES_B2B +term);
    }

    getPlatformsList() {
        return this.baseService.tokenGetRequest(AGENCY_PLATFORMS_B2B + "?type=AIR");
    }

    getProvidersList() {
        return this.baseService.tokenGetRequest(AGENCY_FLIGHT_PROVIDERS_B2B_URL);
    }

    getFlightBookings(firstDay, lastDay, dateType, modeType, zone, company, status, pnr, agent, customerName, paxName, eticketNumber, agencyID, platformID, provider, per_page, page) {

        const urlBuilder = new UrlBuilder(FLIGHT_MANAGE_BOOKINGS_FLIGHTS_B2B_URL)
            .addParam("firstDay", firstDay)
            .addParam("lastDay", lastDay)
            .addParam("date_type", dateType);

        if (modeType) {
            urlBuilder.addParam("type", modeType);
        }

        if (zone) {
            urlBuilder.addParam("zone", zone);
        }

        if (company) {
            urlBuilder.addParam("carrier", company);
        }

        if (status) {
            urlBuilder.addParam("status", status);
        }

        if (pnr) {
            urlBuilder.addParam("pnr", pnr);
        }

        if (agent) {
            urlBuilder.addParam("agentUsername", agent);
        }

        if (customerName) {
            urlBuilder.addParam("customer", customerName);
        }

        if (paxName) {
            urlBuilder.addParam("pax", paxName);
        }

        if(eticketNumber){
            urlBuilder.addParam("eticketNumber", eticketNumber);
        }

        if (agencyID) {
            urlBuilder.addParam("agency_id", agencyID);
        }

        if (platformID) {
            urlBuilder.addParam("platform_id", platformID);
        }
		
		if(provider){
            urlBuilder.addParam("provider_id", provider.id);
		}

		if(per_page){
			urlBuilder.addParam("per_page", per_page);
		}

		if(page){
			urlBuilder.addParam("page", page);
		}

        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }

    getEticket(id) {

        const urlBuilder = new UrlBuilder(FLIGHT_PNR_B2B_URL)
            .addParam("id", id);

        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }

    getEticketByPNR(pnr,name) {
        const urlBuilder = new UrlBuilder(FLIGHT_ETICKET_PNR_URL)
            .addParam("pnr", pnr).addParam("name", name);
        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }
    getPNR(pnr, oid, provider_code) {

        const urlBuilder = new UrlBuilder(FLIGHT_GET_PNR_B2B_URL)
            .addParam("recordLocator", pnr)
            .addParam("provider_code", provider_code)
            .addParam("OID", oid);

        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }

    refreshETicket(id) {

        const urlBuilder = new UrlBuilder(FLIGHT_REFRESH_B2B_URL)
            .addParam("id", id);

        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }

    getCryptic(id){
        return this.baseService.tokenGetRequest(FLIGHT_CRYPTIC_B2B_URL + '?id=' + id);
    }

    cancelETicket(id) {
        return this.baseService.tokenPostRequest(FLIGHT_CANCEL_ETICKET_B2B_URL, { id: id });
    }

    cancelETicketAdmin(cancelEticketNumber, cancelRefundAmount, cancelFee, id) {
        const req = {  cancelEticketNumber: cancelEticketNumber, cancelRefundAmount: +cancelRefundAmount, cancelFee: +cancelFee, id: id };
        return this.baseService.tokenPostRequest(FLIGHT_CANCEL_ETICKET_B2B_ADMIN_URL, req);
    }

    transfertETicket(agency_id, eTicket_id) {
        return this.baseService.tokenPostRequest(FLIGHT_TRANSFERT_ETICKET_B2B_URL, { agency_id: agency_id, id: eTicket_id });
    }

    confirmOnHoldETicket(id) {

        const urlBuilder = new UrlBuilder(FLIGHT_CONFIRM_ONHOLD_ETICKET_B2B_URL)
            .addParam("id", id);

        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    }

    synchETicket(data) {
        return this.baseService.tokenPostRequest(FLIGHT_SYNCH_ETICKET_B2B_URL, data);
    }

}
