export class SharedRoomModel {

    adult_nbr: number;
    child_nbr: number;
    child_age: number[];

    constructor() {
        this.adult_nbr = 2;
        this.child_nbr = 0;
        this.child_age = [0,0,0,0,0,0,0,0];
    }
}

