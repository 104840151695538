import * as tslib_1 from "tslib";
import { BaseService } from './base.service';
import { HOTEL_CONFIRMATION_API_URL } from "./urls_b2c";
import { HOTEL_CONFIRMATION_B2B_URL } from "./urls_b2b";
var HotelConfirmationService = /** @class */ (function () {
    function HotelConfirmationService(baseService) {
        this.baseService = baseService;
        this.confirmationModalElement = null;
    }
    HotelConfirmationService.prototype.getHotelConfirmationModalElement = function () {
        return this.confirmationModalElement;
    };
    HotelConfirmationService.prototype.setHotelConfirmationModalElement = function (data) {
        this.confirmationModalElement = tslib_1.__assign({}, data);
    };
    HotelConfirmationService.prototype.confirmHotelBooking = function (request) {
        var correctUrl;
        (this.baseService.b2bUserType == "B2B") ? correctUrl = HOTEL_CONFIRMATION_B2B_URL : correctUrl = HOTEL_CONFIRMATION_API_URL;
        return this.baseService.tokenPostRequest(correctUrl, request);
    };
    return HotelConfirmationService;
}());
export { HotelConfirmationService };
