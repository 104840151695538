/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./modal.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./modal.component";
var styles_ModalComponent = [i0.styles];
var RenderType_ModalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ModalComponent, data: {} });
export { RenderType_ModalComponent as RenderType_ModalComponent };
function View_ModalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "flight-details-footer"], ["style", "height:60px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "button", [["class", "search-button btn transition-effect"], ["style", "margin-right:10px; float:right;"], ["type", "submit"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.sendEvent() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.actionButton)); _ck(_v, 2, 0, currVal_0); }); }
function View_ModalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "col-md-11 col-sm-12 col-xs-12 book-btn-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "button", [["class", "book-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bookPackageAction() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "disabled-button": 0 }), (_l()(), i1.ɵted(4, null, ["", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = "book-button"; var currVal_1 = _ck(_v, 3, 0, _co.disableButton); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform("BOOK")); _ck(_v, 4, 0, currVal_2); }); }
function View_ModalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "full-modal animated fadeIn"], ["style", "overflow: hidden"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "ab-modal-box App-Model-Hotel-Photos"]], [[4, "height", "%"], [4, "width", "%"]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "header-top flight-details-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "close"], ["id", "modal_close_button"], ["style", "background: none; color: white;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fa fa-times"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "modal-overflow"]], null, null, null, null, null)), i1.ɵncd(null, 0), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_2)), i1.ɵdid(13, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_3)), i1.ɵdid(15, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.actionButton !== ""); _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.bookPackage; _ck(_v, 15, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.modalHeight; var currVal_1 = _co.modalWidth; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.title; _ck(_v, 8, 0, currVal_2); }); }
export function View_ModalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ModalComponent_1)), i1.ɵdid(1, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showModal; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ModalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-modal", [], null, null, null, View_ModalComponent_0, RenderType_ModalComponent)), i1.ɵdid(1, 114688, null, 0, i4.ModalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalComponentNgFactory = i1.ɵccf("app-modal", i4.ModalComponent, View_ModalComponent_Host_0, { showModal: "showModal", title: "title", modalWidth: "modalWidth", modalWidthPx: "modalWidthPx", modalHeight: "modalHeight", actionButton: "actionButton", disableButton: "disableButton", bookPackage: "bookPackage" }, { bookPackageClicked: "bookPackageClicked", buttonClicked: "buttonClicked", closeModalFrom2NDParent: "closeModalFrom2NDParent" }, ["*"]);
export { ModalComponentNgFactory as ModalComponentNgFactory };
