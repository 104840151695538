import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { FLIGHT_CONFIRMATION_API_URL } from "./urls_b2c";
import { FLIGHT_CONFIRMATION_B2B_URL } from "./urls_b2b";

@Injectable()

export class FlightConfirmationService {

    confirmationModalElement = null;

    constructor(private baseService: BaseService) {
    }


    confirmFlightBooking(request) {

        let correctUrl;
        (this.baseService.b2bUserType == "B2B") ? correctUrl = FLIGHT_CONFIRMATION_B2B_URL : correctUrl = FLIGHT_CONFIRMATION_API_URL;

        return this.baseService.tokenPostRequest(correctUrl, request);
    }

}
