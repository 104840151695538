import { HttpLoaderFactory } from '@app/HttpLoaderFactory';
import { FlightService } from './services/flight.service';
import { HotelService } from './services/hotel.service';
import { PackagesService } from './services/packages.service';
import { BaseService } from './services/base.service';
import { CustomDatepickerI18nService } from './services/custom-datepicker-i18n.service';
import { FlightConfirmationService } from './services/flight.confirmation.service';
import { CharterManageService } from './services/charter-manage.service';
import { HotelConfirmationService } from './services/hotel.confirmation.service';
var ɵ0 = HttpLoaderFactory;
var SharedModule = /** @class */ (function () {
    function SharedModule() {
    }
    SharedModule.forRoot = function () {
        return {
            ngModule: SharedModule,
            providers: [FlightService, HotelService, HotelConfirmationService, PackagesService, BaseService, FlightConfirmationService, CustomDatepickerI18nService, CharterManageService] // to ensure one single instance of this service is created and shared between all the modules
        };
    };
    return SharedModule;
}());
export { SharedModule };
export { ɵ0 };
