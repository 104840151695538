<div class="container" *ngIf="data">
	<div class="top-section">
		<div class="invoice-info">
			<table>
				<thead>
					<tr>
						<th style="padding-right: 1.5rem !important">{{"INVOICE"|translate}}</th>
						<th>#{{ data.invoice_number }}</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>{{"DATE"|translate}}</td>
						<td>{{ data.creation_date }}</td>
					</tr>
					<tr>
						<td>{{"DUE_DATE"|translate}}</td>
						<td>{{ data.due_date }}</td>
					</tr>
					<tr>
						<td>{{"TOTAL"|translate}}</td>
						<td>
							{{ formatEntry(data.total_price, "price") }} DZD
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="platform-info">
			<div class="card">
				<!-- <div class="logo">
					<img
						src="/assets/algerie-booking/triponline/logo-tpo-large-vertical.png"
						alt=""
						srcset=""
					/>
				</div> -->

			</div>
		</div>
	</div>
	<br />
	<br />

	<div class="ic">
		<div class="tb">
			<div class="title">{{"INVOICE"|translate}}</div>
			<br />
			<table class="data-table">
				<thead>
					<tr class="bb">
						<th>{{"NAME"|translate}}</th>
						<th class="text-center">{{"BOOKING_TYPE"|translate}}</th>
						<th>{{"PRICE"|translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of data.invoice_items" class="b-b">
						<td>
							{{ item.item_name }}
						</td>
						<td class="text-center">
							{{ item.booking_type|translate }}
						</td>
						<td>{{ formatEntry(item.unit_price, "price") }} DZD</td>
					</tr>
					<tr></tr>
					<tr></tr>
				</tbody>
				<tfoot>
					<tr>
						<td colspan="2" style="padding-top: 2rem!important">{{"TOTAL"|translate}}</td>
						<td style="padding-top: 2rem!important;white-space: nowrap;">
							{{ formatEntry(data.total_price, "price") }} DZD
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	</div>

	<br />
	<br />
	<div class="ic">
		<div class="tb">
			<div class="title">{{"PAYMENTS"|translate}}</div>
			<br />
			<table class="data-table">
				<thead>
					<tr class="bb">
						<th>N°</th>
						<th style="padding-right:.8rem!important;padding-left:.8rem!important">{{"DATE"|translate}}</th>
						<th class="text-center" style="white-space:nowrap">{{"NOTE"|translate}}</th>
						<th class="text-center">{{"TYPE"|translate}}</th>
						<th>{{"AMOUNT"|translate}}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of data.payments; index as i" class="b-b">
						<td style="color: gray">
							{{ i + 1 }}
						</td>
						<td style="padding-right:.8rem!important;padding-left:.8rem!important">
							{{ item.payment_date }}
						</td>
						<td class="text-center" style="padding-right:.8rem!important;padding-left:.8rem!important">
							{{ item.note }}
						</td>
						<td class="text-center" style="padding-right:.8rem!important;padding-left:.8rem!important">
							{{ item.payment_type|translate }}
						</td>
						<td>{{ formatEntry(item.amount, "price") }} DZD</td>
					</tr>
					
			
				
					<tr>
						<td colspan="4" style="padding-top: 2rem!important; font-weight: 500; color: rgb(65, 66, 107); ">
							{{"PAID_AMOUNT"|translate}}
						</td>
						<td style="padding-top: 2rem!important;white-space: nowrap; font-weight: 500; color: rgb(65, 66, 107); ">
							{{ formatEntry(data.paid_amount, "price") }} DZD
						</td>
					</tr>
					<tr>
						<td colspan="4" style=" font-weight: 500; color: rgb(65, 66, 107); ">{{"AMOUNT_LEFT"|translate}}</td>
						<td style="white-space:nowrap; font-weight: 500; color: rgb(65, 66, 107); ">
							{{ formatEntry(data.amount_left, "price") }} DZD
						</td>
					</tr>
					</tbody>
			</table>
		</div>
	</div>
</div>
