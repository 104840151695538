import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { tap, map, catchError, switchMap } from 'rxjs/operators';
import { WebService } from '@app/web.service';
import { TranslateService } from '@ngx-translate/core';
import { throwError, BehaviorSubject, of } from 'rxjs';
import { ShareVars } from './share.vars';
import { DateService } from './date.service';
import { ERROR_MESSAGE_GETTING_DATA, ERROR_INTERNET_CONNECTION, ERROR_ACTION_NOT_AUTHORIZED, ERROR_ACTION_NOT_AUTHORIZED_TITLE, ERROR_MESSAGE_INSUFFICIENT_FUNDS, ERROR_ACTION_FUNDS_TITLE } from "./display.messages";
import { USER_API_URL, FLIGHT_PRINT_BOOKING_B2C, HOTEL_PRINT_BOOKING_B2C, PACKAGE_PRINT_BOOKING_B2C, FLIGHT_SEND_BOOKING_BYEMAIL_B2C_URL, HOTEL_SEND_BOOKING_BYEMAIL_B2C_URL, PACKAGE_SEND_BOOKING_BYEMAIL_B2C_URL } from "./urls_b2c";
import { USER_SESSION, FLIGHT_SEND_BOOKING_BYEMAIL_B2B_URL, HOTEL_SEND_BOOKING_BYEMAIL_B2B_URL, FLIGHT_PRINT_BOOKING_B2B, PACKAGE_SEND_BOOKING_BYEMAIL_B2B_URL, HOTEL_PRINT_BOOKING_B2B, PACKAGE_PRINT_BOOKING_B2B, CHARTER_PRINT_BOOKING_B2B, AGENT_B2B_URL, AGENCY_AGENCIES_B2B, AGENCY_PLATFORMS_B2B } from "./urls_b2b";
import { Location } from '@angular/common';
import { NotyfService } from 'ng-notyf';
import { NavigationService } from 'app/shared/services/navigation.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../web.service";
import * as i3 from "@ngx-translate/core";
import * as i4 from "@angular/common";
import * as i5 from "ng-notyf";
import * as i6 from "./navigation.service";
var TAG = 'baseService::';
var KEY_TOKEN = 'token';
var KEY_USER = 'user';
var KEY_LANGUAGE = 'language';
export var ENGLISH = 'en';
export var FRENCH = 'fr';
export var ARABIC = 'ar';
var DEFAULT_LANGUAGE = FRENCH;
var PLATFORM_MOBILE = 'mobile';
var PLATFORM_WEB = 'web';
var FLIGHT = 'flight';
var PACKAGE = 'package';
var HOTEL = 'hotel';
var VISA = 'visa';
var CHARTER = "charter";
var URL_FLIGHT_PRINT_BOOKING_B2C = FLIGHT_PRINT_BOOKING_B2C;
var URL_HOTEL_PRINT_BOOKING_B2C = HOTEL_PRINT_BOOKING_B2C;
var URL_PACKAGE_PRINT_BOOKING_B2C = PACKAGE_PRINT_BOOKING_B2C;
var URL_FLIGHT_SEND_BOOKING_BYEMAIL_B2C = FLIGHT_SEND_BOOKING_BYEMAIL_B2C_URL;
var URL_HOTEL_SEND_BOOKING_BYEMAIL_B2C = HOTEL_SEND_BOOKING_BYEMAIL_B2C_URL;
var URL_PACKAGE_SEND_BOOKING_BYEMAIL_B2C = PACKAGE_SEND_BOOKING_BYEMAIL_B2C_URL;
var URL_FLIGHT_PRINT_BOOKING_B2B = FLIGHT_PRINT_BOOKING_B2B;
var URL_HOTEL_PRINT_BOOKING_B2B = HOTEL_PRINT_BOOKING_B2B;
var URL_PACKAGE_PRINT_BOOKING_B2B = PACKAGE_PRINT_BOOKING_B2B;
var URL_CHARTER_PRINT_BOOKING_B2B = CHARTER_PRINT_BOOKING_B2B;
var URL_FLIGHT_SEND_BOOKING_BYEMAIL_B2B = FLIGHT_SEND_BOOKING_BYEMAIL_B2B_URL;
var URL_HOTEL_SEND_BOOKING_BYEMAIL_B2B = HOTEL_SEND_BOOKING_BYEMAIL_B2B_URL;
var URL_PACKAGE_SEND_BOOKING_BYEMAIL_B2B = PACKAGE_SEND_BOOKING_BYEMAIL_B2B_URL;
var BaseService = /** @class */ (function () {
    function BaseService(httpClient, platformService, translator, location, Notyf, navigationService) {
        var _this = this;
        this.httpClient = httpClient;
        this.platformService = platformService;
        this.translator = translator;
        this.location = location;
        this.Notyf = Notyf;
        this.navigationService = navigationService;
        this.paxType = null;
        this.b2bUser = new BehaviorSubject(null);
        this.b2bUserType = '';
        this.isB2CEnabled = new BehaviorSubject(true);
        this.agency = new BehaviorSubject(null);
        this.unreadMessages = 0;
        this.allow_epayment = false;
        this.currentPlatform = null;
        this.logResponse = function (funcName, url) { return tap(function (data) { return _this.logSuccess(funcName, url, data); }, function (error) { return _this.logError(funcName, url, error); }); };
        this.getData = function (key) { return _this.platformService.getData(key); };
        this.deleteData = function (key) { _this.platformService.deleteData(key); };
        this.setToken = function (token) { token ? _this.saveData(KEY_TOKEN, token) : null; };
        this.getToken = function () { return _this.getData(KEY_TOKEN); };
        this.deleteToken = function () { _this.deleteData(KEY_TOKEN); };
        this.setUser = function (user) { return user ? _this.platformService.saveData(KEY_USER, user) : null; };
        this.getUser = function () { return _this.platformService.getData(KEY_USER); };
        this.deleteUser = function () { _this.platformService.deleteData(KEY_USER); };
        this.isArabic = function () { return _this.getLanguage() === ARABIC; };
        this.isFrench = function () { return _this.getLanguage() === FRENCH; };
        this.isEnglish = function () { return _this.getLanguage() === ENGLISH; };
        this.initLanguage = function () { _this.getLanguage() ? _this.translator.use(_this.getLanguage()) : _this.initTrnaslator(); };
        this.getGPSPosition = function () { return _this.platformService.getGPSPostion(); };
        this.isMobile = function () { return _this.platformService.getPlatformName() === PLATFORM_MOBILE; };
        this.isWeb = function () { return _this.platformService.getPlatformName() === PLATFORM_WEB; };
        this.routerEventsObservable = function () { return _this.platformService.routerEventsObservable(); };
        this.getRoutePath = function () { return _this.platformService.getRoutePath(); };
        this.getPaxType = function () { return _this.paxType; };
        this.setPaxType = function (type) { _this.paxType = type; };
        this.isEmptyPax = function () { return _this.getPaxType() == null; };
        this.isFlightPax = function () { return _this.getPaxType() == FLIGHT; };
        this.isPackagePax = function () { return _this.getPaxType() == PACKAGE; };
        this.isHotelPax = function () { return _this.getPaxType() == HOTEL; };
        this.isVisaPax = function () { return _this.getPaxType() == VISA; };
        this.isCharterPax = function () { return _this.getPaxType() == CHARTER; };
        this.getUserToken = function (headers) { return _this.httpClient.get(USER_API_URL, { headers: headers, observe: 'response' }); };
        this.pickDate_mobile = function (callback) { _this.platformService.pickDate(callback); };
        this.setUserTypeCookies = function (data) { _this.saveData('type', data); };
        this.getUserTypeCookies = function () { return _this.getData('type'); };
        this.deleteUserTypeCookies = function () { _this.deleteData('type'); };
        this.setB2BUser = function (data) { _this.b2bUser.next(data); };
        this.getB2BUser = function () { return _this.b2bUser; };
        this.deleteB2BUser = function () { _this.b2bUser = new BehaviorSubject(null); };
        this.getB2BUserService = function () { return _this.tokenGetRequest(USER_SESSION); };
        this.isB2BUserCookies = function () { return _this.b2bUserType == 'B2B'; };
        this.getB2CState = function () { return _this.isB2CEnabled; };
        this.enableB2C = function () { _this.isB2CEnabled.next(true); };
        this.disableB2C = function () { _this.isB2CEnabled.next(false); };
        this.b2bUserType = environment.mode;
        if (this.getUserTypeCookies() && this.getUserTypeCookies() == 'B2B') {
            ShareVars.waitingB2BUser = true;
            this.b2bUserType = 'B2B';
        }
    }
    BaseService.prototype.putRequestCode = function (url, headers, putData) {
        url = this.addLanguageToUrl(url);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            return this.httpClient.put(url, putData, { headers: headers, observe: 'response' }).pipe(map(this.successTokenRequest), catchError(this.errorTokenRequest));
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.postRequestCode = function (url, headers, postData) {
        url = this.addLanguageToUrl(url);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            return this.httpClient.post(url, postData, { headers: headers, observe: 'response' }).pipe(map(this.successTokenRequest), catchError(this.errorTokenRequest));
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.getRequestCode = function (url, headers, handleError) {
        var _this = this;
        if (handleError === void 0) { handleError = true; }
        url = this.addLanguageToUrl(url);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            return this.httpClient.get(url, { headers: headers, observe: 'response' }).pipe(map(this.successTokenRequest), catchError(function (error) {
                if (handleError) {
                    return _this.errorTokenRequest(error);
                }
                return throwError(error);
            }));
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.deleteRequestCode = function (url, headers) {
        var _this = this;
        url = this.addLanguageToUrl(url);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            return this.httpClient.delete(url, { headers: headers, observe: 'response' }).pipe(map(this.successTokenRequest), catchError(function (error) {
                return _this.errorTokenRequest(error);
            }));
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.selectPlatformKey = function () {
        return environment.key;
    };
    BaseService.prototype.createRequestHeaders = function (token) {
        if (token == null) {
            token = this.getToken();
        }
        // TODO find a good way to detect if we are on b2b or b2c, because i think there is no clear way to do it at the moment
        // let platformKey = environment.key;
        var platformKey = this.selectPlatformKey();
        var headers = new HttpHeaders({ "token": token, "key": platformKey });
        return headers;
    };
    BaseService.prototype.setPutNewToken = function (data, url, putData) {
        data = this.successTokenRequest(data);
        var token;
        if (data['session']) {
            token = data['session']['token'];
        }
        else {
            token = '';
        }
        this.setToken(token);
        var headers = this.createRequestHeaders(token);
        return this.putRequestCode(url, headers, putData);
    };
    BaseService.prototype.setPostNewToken = function (data, url, postData) {
        data = this.successTokenRequest(data);
        var token;
        if (data['session']) {
            token = data['session']['token'];
        }
        else {
            token = '';
        }
        this.setToken(token);
        var headers = this.createRequestHeaders(token);
        return this.postRequestCode(url, headers, postData);
    };
    BaseService.prototype.setGetNewToken = function (data, url) {
        data = this.successTokenRequest(data);
        ShareVars.authorizations = data["product_authorizations"] || data["authorizations"] || [];
        ;
        var token = data['session'] ? data['session']['token'] : '';
        this.setToken(token);
        var headers = this.createRequestHeaders(token);
        return this.getRequestCode(url, headers);
    };
    BaseService.prototype.setDeleteNewToken = function (data, url) {
        data = this.successTokenRequest(data);
        var token = data['session'] ? data['session']['token'] : '';
        this.setToken(token);
        var headers = this.createRequestHeaders(token);
        return this.deleteRequestCode(url, headers);
    };
    BaseService.prototype.tokenPostRequest = function (url, postData) {
        var _this = this;
        var token = this.getToken();
        var headers = this.createRequestHeaders(token);
        if (!token && this.b2bUserType == 'B2C') {
            return this.getUserToken(headers).pipe(switchMap(function (tokenData) { return _this.setPostNewToken(tokenData, url, postData); }));
        }
        else {
            return this.postRequestCode(url, headers, postData);
        }
    };
    BaseService.prototype.tokenPostLoginRequest = function (url, postData) {
        var token = this.getToken();
        var headers = this.createRequestHeaders(token);
        return this.postRequestCode(url, headers, postData);
    };
    BaseService.prototype.addLanguageToUrl = function (url) {
        var separator = url.indexOf('?') === -1 ? '?' : '&';
        url = url + separator + 'language=' + this.getLanguage();
        return url;
    };
    BaseService.prototype.tokenGetRequest = function (url, handleError) {
        var _this = this;
        if (handleError === void 0) { handleError = true; }
        var token = this.getToken();
        var headers = this.createRequestHeaders(token);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            if (!token && url != USER_API_URL && this.b2bUserType == 'B2C') {
                return this.getUserToken(headers).pipe(switchMap(function (tokenData) { return _this.setGetNewToken(tokenData, url); }));
            }
            else {
                return this.getRequestCode(url, headers, handleError);
            }
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.tokenPutRequest = function (url, putData) {
        var _this = this;
        var token = this.getToken();
        var headers = this.createRequestHeaders(token);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            if (!token && this.b2bUserType == 'B2C') {
                return this.getUserToken(headers).pipe(switchMap(function (tokenData) { return _this.setPutNewToken(tokenData, url, putData); }));
            }
            else {
                return this.putRequestCode(url, headers, putData);
            }
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.tokenDeleteRequest = function (url) {
        var _this = this;
        var token = this.getToken();
        var headers = this.createRequestHeaders(token);
        if (!this.isB2BUrlUnauthorized(url) && !this.isB2CUrlUnauthorized(url)) {
            if (!token && url != USER_API_URL && this.b2bUserType == 'B2C') {
                return this.getUserToken(headers).pipe(switchMap(function (tokenData) { return _this.setDeleteNewToken(tokenData, url); }));
            }
            else {
                return this.deleteRequestCode(url, headers);
            }
        }
        else {
            var error = new HttpErrorResponse({ status: 401 });
            return of(error);
        }
    };
    BaseService.prototype.successTokenRequest = function (data) {
        var response = data;
        if (data['body']) {
            response = data.body;
        }
        var result = response;
        if (response['result']) {
            result = response['result'];
        }
        if (result['error']) {
            if ((result['error']['code'] !== 'NO_AVAIL_FOUND') && !(ShareVars.excludeErrors.includes(result['description']))
                && (result['error'] != 'The returned response is empty')) {
                if (result['error']['messages'] && result['error']['messages'].length) {
                    ShareVars.errorMessage = result['error']['messages'][0]['txt'];
                }
                else if (result['description']) {
                    ShareVars.errorMessage = result['description'];
                }
                else if (result['error']['message']) {
                    ShareVars.errorMessage = result['error']['message'];
                }
                else {
                    return response;
                }
                ShareVars.showError = true;
                ShareVars.errorTitle = 'ERROR';
            }
        }
        return response;
    };
    BaseService.prototype.errorTokenRequest = function (errorReq) {
        console.log("Log Error:", errorReq);
        var errorResponse = {
            errorStatus: null,
            errorMSG: null,
            errorSide: null
        };
        ShareVars.errorStatus = null;
        errorResponse.errorMSG = errorReq.message;
        errorResponse.errorStatus = errorReq.status;
        if (errorReq.error instanceof ErrorEvent) {
            errorResponse.errorSide = 'Client side error';
            ShareVars.showError = true;
            ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
        }
        else {
            errorResponse.errorSide = 'Server side error';
            if (errorReq.error instanceof ProgressEvent) {
                ShareVars.showError = true;
                ShareVars.errorMessage = ERROR_INTERNET_CONNECTION;
                return throwError(errorResponse);
            }
            ShareVars.errorStatus = errorReq.status;
            ShareVars.showError = true;
            ShareVars.showChildLoader = false;
            ShareVars.childLoaderMessage = null;
            switch (ShareVars.errorStatus) {
                case null:
                    break;
                case 401:
                    this.deleteB2BUser();
                    this.deleteUserTypeCookies();
                    ShareVars.waitingB2BUser = false;
                    if (this.b2bUserType == 'B2B') {
                        ShareVars.showError = false;
                        this.navigationService.goToB2BLogin();
                    }
                    else {
                        //show error for b2c only
                        ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
                        ShareVars.errorTitle = ERROR_ACTION_NOT_AUTHORIZED_TITLE;
                    }
                    break;
                case 402:
                    ShareVars.errorMessage = ERROR_MESSAGE_INSUFFICIENT_FUNDS;
                    ShareVars.errorTitle = ERROR_ACTION_FUNDS_TITLE;
                    break;
                case 403:
                    ShareVars.errorMessage = ERROR_ACTION_NOT_AUTHORIZED;
                    ShareVars.errorTitle = ERROR_ACTION_NOT_AUTHORIZED_TITLE;
                    break;
                case 500:
                    ShareVars.showError = false;
                    break;
                case 498:
                    ShareVars.showError = false;
                    var url = '/b2c/change-password';
                    if (this.b2bUserType == 'B2B') {
                        url = '/b2b/change-password';
                    }
                    window.location.href = url;
                    break;
                default:
                    ShareVars.errorMessage = ERROR_MESSAGE_GETTING_DATA;
                    ShareVars.errorTitle = ERROR_ACTION_NOT_AUTHORIZED_TITLE;
            }
        }
        return throwError(errorResponse);
    };
    BaseService.prototype.logSuccess = function (funcName, url, data) {
        var FUNC_TAG = TAG + funcName + ':';
        var SUCCESS_TAG = FUNC_TAG + ' \nurl: ' + url + ' \ndata: ';
        console.log(SUCCESS_TAG);
        console.dir(data);
    };
    BaseService.prototype.logError = function (funcName, url, error) {
        var FUNC_TAG = TAG + funcName + ':';
        var ERROR_TAG = FUNC_TAG + ' \nurl:' + url + ' \nerror: ';
        console.log(ERROR_TAG);
        console.dir(error);
    };
    BaseService.prototype.saveData = function (key, value) {
        if (typeof value !== typeof '') {
            value = JSON.stringify(value);
        }
        this.platformService.saveData(key, value);
    };
    BaseService.prototype.getLanguage = function () {
        var lang = this.platformService.getData(KEY_LANGUAGE);
        if (lang) {
            return lang;
        }
        return this.initTrnaslator();
    };
    BaseService.prototype.setLanguage = function (language) {
        if (!language) {
            return;
        }
        this.platformService.saveData(KEY_LANGUAGE, language);
        this.translator.use(language);
    };
    BaseService.prototype.initTrnaslator = function () {
        var langs = ['en', 'fr', 'ar'];
        this.translator.addLangs(langs);
        this.translator.setDefaultLang(DEFAULT_LANGUAGE);
        this.setLanguage(DEFAULT_LANGUAGE);
        return DEFAULT_LANGUAGE;
    };
    BaseService.prototype.getIconSource_mobile = function (icon) {
        var iconPrefix = this.platformService.isAndroid()
            ? 'res://'
            : 'res://tabIcons/';
        return iconPrefix + icon;
    };
    BaseService.prototype.focusOnElement = function (id) {
        var elm = document.getElementById(id);
        if (elm != null) {
            setTimeout(function () {
                elm.click();
                elm.focus();
            }, 10);
        }
    };
    BaseService.prototype.compareDates = function (date1, date2) {
        // date1 and date2 must be DateService.toObjectDate result {year: X, month: Y, day: Z}
        var d1 = null;
        var d2 = null;
        d1 = DateService.toNewDate(date1).getTime();
        d2 = DateService.toNewDate(date2).getTime();
        if (d1 > d2) {
            return 1;
        }
        else if (d1 < d2) {
            return -1;
        }
        else {
            return 0;
        }
    };
    BaseService.prototype.fixFlightParamsFromURL = function (queryParams) {
        queryParams['bounds'] = +queryParams['bounds'];
        queryParams['nrOfAdult'] = +queryParams['nrOfAdult'];
        queryParams['nrOfChild'] = +queryParams['nrOfChild'];
        queryParams['nrOfInfant'] = +queryParams['nrOfInfant'];
        queryParams['nrOfInfantWithSeat'] = +queryParams['nrOfInfantWithSeat'];
        queryParams['nrOfYouth'] = +queryParams['nrOfYouth'];
        queryParams['nrOfSenior'] = +queryParams['nrOfSenior'];
        queryParams['range'] = +queryParams['range'];
        queryParams['withBaggage'] = +queryParams['withBaggage'];
        queryParams['refundable'] = +queryParams['refundable'];
        queryParams['directFlight'] = +queryParams['directFlight'];
        queryParams['openReturn'] = +queryParams['openReturn'];
        queryParams['requestedFlightNonStop'] = +queryParams['requestedFlightNonStop'];
        queryParams['requestedMultiCity'] = +queryParams['requestedMultiCity'];
        switch (queryParams['flexible']) {
            case 'true':
                queryParams['flexible'] = true;
                break;
            case 'false':
                queryParams['flexible'] = false;
                break;
            default:
                queryParams['flexible'] = true;
                break;
        }
        return queryParams;
    };
    BaseService.prototype.fixHotelParamsFromURL = function (queryParams) {
        queryParams['adult_total'] = +queryParams['adult_total'];
        queryParams['child_total'] = +queryParams['child_total'];
        queryParams['rooms'] = +queryParams['rooms'];
        queryParams['nights'] = +queryParams['nights'];
        queryParams['totalProviders'] = +queryParams['totalProviders'];
        queryParams['hotelDepartureID'] = +queryParams['hotelDepartureID'];
        if (queryParams['image']) {
            queryParams['image'] = +queryParams['image'];
        }
        for (var i = 0; i < queryParams['rooms']; i++) {
            queryParams['adults_nbr_' + (i + 1)] = +queryParams['adults_nbr_' + (i + 1)];
            queryParams['child_nbr_' + (i + 1)] = +queryParams['child_nbr_' + (i + 1)];
        }
        return queryParams;
    };
    BaseService.prototype.formatPrice = function (price) {
        var priceType = '';
        var newPrice = '';
        var decimalPart;
        if (price < 0) {
            priceType = '- ';
        }
        price = Math.abs(price);
        ((price + '').indexOf('.') !== -1) ? decimalPart = (price + '').slice((price + '').indexOf('.') + 1, (price + '').indexOf('.') + 3) : decimalPart = '00';
        price = Math.floor(price);
        var priceString = price + '';
        var priceArray = priceString.split('');
        priceArray = priceArray.reverse();
        for (var i = 0; i < priceArray.length; i++) {
            newPrice += priceArray[i];
            if ((i + 1) % 3 == 0 && i > 0 && i + 1 != priceArray.length) {
                newPrice += '.';
            }
        }
        var newPriceArray = newPrice.split('');
        newPrice = newPriceArray.reverse().join('');
        return (priceType + newPrice + ',' + decimalPart);
    };
    BaseService.prototype.formatDates = function (date) {
        var dd = date.slice(8, 10);
        var mm = date.slice(5, 7);
        var yy = date.slice(0, 4);
        return dd + '-' + mm + '-' + yy;
    };
    BaseService.prototype.resetPassword = function (url, token, data) {
        var headers = this.createRequestHeaders(token);
        return this.postRequestCode(url, headers, data);
    };
    BaseService.prototype.getPrintBookingUrl = function (id, token, mode, type) {
        var printURL = eval('URL_' + type + '_PRINT_BOOKING_' + mode);
        var identifier = "reference";
        if ((type == "FLIGHT") || (type == "CHARTER")) {
            identifier = "id";
        }
        printURL = printURL + "?" + identifier + "=" + id + "&token=" + token;
        return printURL;
    };
    BaseService.prototype.printBooking = function (id, token, mode, type) {
        window.open(this.getPrintBookingUrl(id, token, mode, type), "_blank");
    };
    BaseService.prototype.sendBookingByEmail = function (id, email, mode, type) {
        var url = eval('URL_' + type + '_SEND_BOOKING_BYEMAIL_' + mode);
        var identifier = 'reference';
        if (type == 'FLIGHT') {
            identifier = 'id';
        }
        url = url + '?email=' + email + '&' + identifier + '=' + id;
        return this.tokenGetRequest(url);
    };
    BaseService.prototype.scrollToTop = function () {
        var elm = document.getElementById('nav_container');
        if (elm != null) {
            setTimeout(function () { elm.scrollIntoView({ behavior: 'smooth' }); }, 150);
        }
    };
    BaseService.prototype.removeElementFromArray = function (myArray, key) {
        var index = myArray.indexOf(key, 0);
        if (index > -1) {
            myArray.splice(index, 1);
        }
        return myArray;
    };
    BaseService.prototype.getAgentsList = function () {
        return this.tokenGetRequest(AGENT_B2B_URL);
    };
    BaseService.prototype.getAgenciesList = function () {
        return this.tokenGetRequest(AGENCY_AGENCIES_B2B);
    };
    BaseService.prototype.getPlatformsList = function (type) {
        return this.tokenGetRequest(AGENCY_PLATFORMS_B2B + ("?type=" + type));
    };
    BaseService.prototype.showNotyf = function (type, message) {
        return this.Notyf[type]("" + (this.translator.instant(message) ? this.translator.instant(message) : message));
    };
    BaseService.prototype.isB2BUrlUnauthorized = function (url) {
        return (this.b2bUserType == 'B2B' && url.indexOf("/b2c") != -1);
    };
    BaseService.prototype.isB2CUrlUnauthorized = function (url) {
        return (this.b2bUserType == 'B2C' && url.indexOf("/b2b") != -1);
    };
    BaseService.ngInjectableDef = i0.defineInjectable({ factory: function BaseService_Factory() { return new BaseService(i0.inject(i1.HttpClient), i0.inject(i2.WebService), i0.inject(i3.TranslateService), i0.inject(i4.Location), i0.inject(i5.NotyfService), i0.inject(i6.NavigationService)); }, token: BaseService, providedIn: "root" });
    return BaseService;
}());
export { BaseService };
