import { Injectable } from '@angular/core';
import { BaseService } from '@app/shared/services/base.service';
import { UrlBuilder, PACKAGES_AVAILABILITY_API_URL } from '@app/shared/services/urls_b2c';
import { PACKAGES_AVAILABILITY_B2B_URL } from '@app/shared/services/urls_b2b';
import { cloneDeep } from 'lodash';


// todo: move this variable to ./urls_b2c.ts
const PACKAGE_SEARCH_PARAMS = 'packageSearchParams';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {

  roomsToBook = null;
  packageRoomsNumber: number = null;
  departureID: number = null;
  packageID: number = null;
  subPackageID: number = null;
  packageObj = null;
  adultTotalNumber: number = null;
  childTotalNumber: number = null;
  packageBookingConfirmationRequest = null;
  packageRoomRate = null;
  confirmArray = null;

  packageBookingSearchParams = null;
  packageBookingAvailabilityResults = null;

  clientInfos;
  travellersInfosArray;
  tabDetailIndex = 2;
  packageTotalPrice = null;
  selectedPriceID = null;

  constructor(private baseService: BaseService) {
  }

  removeHotelPaxData() {
    this.setPackageBookingPackageRoomsToBook(null);
    this.setPackageBookingPackageRoomsNumber(null);
    this.setPackageBookingPackageDepartureID(null);
    this.setPackageBookingPackageID(null);
    this.setPackageBookingSubPackageID(null);
    this.setPackageBookingPackageObject(null);
    this.setPackageBookingConfirmationRequest(null);
  }


  removeAllData() {
    this.roomsToBook = null;
    this.packageRoomsNumber = null;
    this.departureID = null;
    this.packageID = null;
    this.subPackageID = null;
    this.packageObj = null;
    this.adultTotalNumber = null;
    this.childTotalNumber = null;
    this.packageBookingConfirmationRequest = null;
    this.packageBookingAvailabilityResults = null;
  }

  getPackageBookingSearchParams() {
    return this.packageBookingSearchParams;
  }

  setPackageBookingSearchParams(searchData) {
    this.packageBookingSearchParams = cloneDeep(searchData);
  }

  getPackageBookingAvailabilityResults() {
    return this.packageBookingAvailabilityResults;
  }

  setPackageBookingAvailabilityResults(results) {

    (results) ? this.packageBookingAvailabilityResults = cloneDeep(results) : this.packageBookingAvailabilityResults = null;
  }

  getPackageBookingAdultTotalNumber() {
    return this.adultTotalNumber;
  }

  setPackageBookingAdultTotalNumber(adults: number) {
    this.adultTotalNumber = adults;
  }

  getPackageBookingChildTotalNumber() {
    return this.childTotalNumber;
  }

  setPackageBookingChildTotalNumber(child: number) {
    this.childTotalNumber = child;
  }

  getPackageConfirmArray() {
    return this.confirmArray;
  }

  setPackageConfirmArray(confirmArray) {
    this.confirmArray = confirmArray;
  }

  getPackageBookingPackageObject() {
    return this.packageObj;
  }

  setPackageBookingPackageObject(data) {

    if (data != null) {
      this.packageObj = { ...data };
    } else {
      this.packageObj = data;
    }

  }

  getPackageBookingPackageRoomsToBook() {
    return this.roomsToBook;
  }

  setPackageBookingPackageRoomsToBook(rooms) {
    if (rooms != null) {
      this.roomsToBook = [...rooms];
    } else {
      this.roomsToBook = null;
    }

  }

  getPackageBookingPackageRoomsNumber() {
    return this.packageRoomsNumber;
  }

  setPackageBookingPackageRoomsNumber(roomsNumber: number) {
    this.packageRoomsNumber = roomsNumber;
  }

  setPackageRoomRate(roomRate) {
    this.packageRoomRate = roomRate;
  }

  getPackageRoomRate() {
    return this.packageRoomRate;
  }

  getPackageBookingPackageDepartureID() {
    return this.departureID;
  }

  setPackageBookingPackageDepartureID(departureID: number) {
    this.departureID = departureID;
  }


  getPackageBookingPackageID() {
    return this.packageID;
  }

  setPackageBookingPackageID(packageID: number) {
    this.packageID = packageID;
  }


  getPackageBookingSubPackageID() {
    return this.subPackageID;
  }

  setPackageBookingSubPackageID(subPackageID: number) {
    this.subPackageID = subPackageID;
  }

  setPackageBookingPackageTotalPrice(price){
    this.packageTotalPrice = price;
  }

  getPackageBookingPackageTotalPrice(){
    return this.packageTotalPrice;
  }

  setPackageSelectedPriceID(id){
        this.selectedPriceID = id;
    }

  getPackageSelectedPriceID(){
        return this.selectedPriceID;
    }

  getPackageBookingConfirmationRequest() {
    return this.packageBookingConfirmationRequest;
  }

  setPackageBookingConfirmationRequest(request) {
    this.packageBookingConfirmationRequest = request;
  }

  getPackges(params) {

    let urlBuilder;
    (this.baseService.b2bUserType == "B2B") ? urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_B2B_URL) : urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_API_URL);

    for (let i = 0; i < params['room']; i++) {
      urlBuilder.addParam("adults_" + (i + 1), params['adults_' + (i + 1)]);
      urlBuilder.addParam("children_" + (i + 1), params['children_' + (i + 1)]);
      if (params['children_'+(i+1)] > 0) {

        for (let j = 0; j < params['children_'+(i+1)]; j++) {
          urlBuilder.addParam('child_age_' + (i + 1) + '_' + (j + 1), params['child_age_' + (i + 1) + '_' + (j + 1)]);
        }
      }
    }

    urlBuilder.addParam('customer_nationality', params['customer_nationality'])
      .addParam('departureDate', params['departureDate'])
      .addParam('destinationCity', params["destinationCity"].split("+").join("%2B"))
      .addParam('persons', params['persons'])
      .addParam('room', params['room']);

    return this.baseService.tokenGetRequest(urlBuilder.getUrl());

  }

  searchPackages(params) {

    let urlBuilder;
    (this.baseService.b2bUserType == "B2B") ? urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_B2B_URL) : urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_API_URL);

    for (let i = 0; i < params['room']; i++) {
      urlBuilder.addParam("adults_" + (i + 1), params['adults_' + (i + 1)]);
      urlBuilder.addParam("children_" + (i + 1), params['children_' + (i + 1)]);
      if (params['children_' + (i + 1)] > 0) {

        for (let j = 0; j < params['children_' + (i + 1)]; j++) {
          urlBuilder.addParam('child_age_' + (i + 1) + '_' + (j + 1), params['child_age_' + (i + 1) + '_' + (j + 1)]);
        }
      }
    }

    const availableParams: string[] = ['customer_nationality',
      'beginDate',
      'endDate',
      'destinationCity',
      'persons',
      'room',
      'page',
      'departureCityID'];

    for(let paramKey of availableParams)
    {
      if(paramKey in params && params[paramKey])
      {
        let value = params[paramKey];
        if(paramKey === 'destinationCity') {
          value = value.split("+").join("%2B");
        }
        urlBuilder.addParam(paramKey, value)
      }
    }

    urlBuilder.addParam('per_page', params['per_page'] || 10);

    return this.baseService.tokenGetRequest(urlBuilder.getUrl());

  }

  savePackageSearchParams(params) {
    this.baseService.saveData(PACKAGE_SEARCH_PARAMS, params);
  }

  getPackageSearchParams() {
    return (this.baseService.getData(PACKAGE_SEARCH_PARAMS));
  }

  deletePackageSearchParams() {
    this.baseService.deleteData(PACKAGE_SEARCH_PARAMS);
  }

}
