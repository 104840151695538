export class hotelManageBookingsSaves {

    useService: boolean = false;
    firstDay;
    lastDay;
    userB2B;
    isAdmin;

    /* bookings display */
    bookings = {
        bookings: null,
        originalBookings: null,
        tableBookings: null
    }

    /* date */
    date = {
        dateType: null,
        dateTypeValue: null
    }

    /* mode */
    mode = {
        modeType: null,
        modeTypeValue: null
    }

    /* agencies */
    agenciesData = {
        agencies: null,
        agenciesDPD: null,
        selectedAgencyValue: null,
        selectedAgency: null
    };

    /* platform */
    platformsData = {
        platformsList: null,
        selectedPlatformValue: null,
        selectedPlatform: null,
        platformDPD: null,
    };

    /* validating carriers */
    providersData = {
        providersList: null,
        selectedProviderValue: null,
        selectedProvider: null,
        providerCodeList: null,
        providerLabelsList: null
    };

    /* agents */
    agentsData = {
        agents: null,
        agentsDPD: null,
        selectedAgentValue: null,
        selectedAgent: null,
    };

    /* status */
    statusData = {
        selectedStatus: null,
        selectedStatusValue: null
    };

    /* input text */
    customerName;
    paxName;
    reference;

    constructor() { }
}