import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { componentDeclarations, providerDeclarations } from './shared.common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { NgbModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule, TooltipModule } from "ngx-bootstrap";
import { ModuleWithProviders } from '@angular/compiler/src/core';

import { HttpClient } from '@angular/common/http';
import { HttpLoaderFactory } from '@app/HttpLoaderFactory';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from "@agm/js-marker-clusterer";
import { AgmOverlays } from "agm-overlays";
import { FlightService } from './services/flight.service';
import { HotelService } from './services/hotel.service';
import { PackagesService } from './services/packages.service';
import { BaseService } from './services/base.service';
import { CustomDatepickerI18nService } from './services/custom-datepicker-i18n.service';
import { FlightConfirmationService } from './services/flight.confirmation.service';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CharterManageService } from './services/charter-manage.service';
import { HotelConfirmationService } from './services/hotel.confirmation.service';

@NgModule({
  declarations: [
    ...componentDeclarations    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgbModule,
    NgbDatepickerModule,
    CarouselModule.forRoot(),
    TooltipModule.forRoot(),
    AgmCoreModule.forRoot(
      {
        apiKey: 'AIzaSyC163Xzb_Cg_wqlGTDAvdtWq-K0qD6F2Hs'
      }
    ),
    AgmJsMarkerClustererModule,
    AgmOverlays
  ],
  exports: [
    ...componentDeclarations,
    TranslateModule,
    NgbModule,
    NgbDatepickerModule,
    CarouselModule,
    TooltipModule,
    AgmCoreModule,
    AgmJsMarkerClustererModule,
    AgmOverlays,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    ...providerDeclarations
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [FlightService, HotelService,HotelConfirmationService, PackagesService, BaseService, FlightConfirmationService, CustomDatepickerI18nService, CharterManageService]// to ensure one single instance of this service is created and shared between all the modules
    };
  }
}
