import { CookieService } from 'ngx-cookie-service';
import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "ngx-cookie-service";
import * as i2 from "@angular/router";
var PLATFORM_WEB = 'web';
// !important: every function in this service must declared with same declartion in mobile.service.ts even if it's not needed for mobile (declare it with empty)
var WebService = /** @class */ (function () {
    function WebService(cookieService, router, activatedRoute) {
        this.cookieService = cookieService;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    WebService.prototype.saveData = function (key, value, date) {
        var path = "/";
        this.cookieService.set(key, value, date, path, '', false, 'Lax'); //, date, path, window.location.protocol + window.location.host, true, 'Lax');
    };
    WebService.prototype.getData = function (key) {
        return this.cookieService.get(key);
    };
    WebService.prototype.deleteData = function (key) {
        var path = "/";
        this.cookieService.delete(key, path);
    };
    WebService.prototype.getPlatformName = function () {
        return PLATFORM_WEB;
    };
    WebService.prototype.navigate = function (route, params) {
        var _this = this;
        if (params) {
            this.router.navigate(route, { queryParams: params });
        }
        else {
            this.activatedRoute.queryParams.subscribe(function (urlParams) {
                (Object.keys(urlParams).length > 0) ? _this.router.navigate(route, { queryParams: urlParams }) : _this.router.navigate(route);
            });
        }
    };
    WebService.prototype.backToPreviousPage = function () {
        this.router.navigate(['..']);
    };
    WebService.prototype.queryParamsObservable = function () {
        return this.activatedRoute.queryParams;
    };
    WebService.prototype.getGPSPostion = function () {
        return new Promise(function (resolve) {
            navigator.geolocation.getCurrentPosition(function (position) { return resolve(position.coords); });
        });
    };
    WebService.prototype.routerEventsObservable = function () {
        return this.router.events;
    };
    WebService.prototype.getRoutePath = function () {
        return this.router.url;
    };
    // !important: the following funcitns are  mobile specific function, leave them with empty bodies to avoid errors during the build
    WebService.prototype.setupLoginPageForMobile = function () { };
    WebService.prototype.isAndroid = function () { return false; };
    WebService.prototype.isIos = function () { return false; };
    WebService.prototype.pickDate = function (cb) { };
    WebService.ngInjectableDef = i0.defineInjectable({ factory: function WebService_Factory() { return new WebService(i0.inject(i1.CookieService), i0.inject(i2.Router), i0.inject(i2.ActivatedRoute)); }, token: WebService, providedIn: "root" });
    return WebService;
}());
export { WebService };
