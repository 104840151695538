<div class="forget-password-wrapper">
    <div class="login-form">
        <div class="row">

            <div class="col-md-12 col-sm-12 col-xs-12 text-center reset-title" *ngIf="!sucessResetPWD">
                {{"FORGET_PWD_TITLE"|translate}}
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 text-center" style="font-size: 18px; color: black;" *ngIf="sucessResetPWD">
                {{"FORGET_PWD_SUCCESS"|translate}}
            </div>

            <div class="col-md-12 col-sm-12 col-xs-12 text-center" *ngIf="sucessResetPWD">
                <button style="width: 100%; margin-top: 50px; font-size: 15px" (click)="goToLoginPage()">
                    {{"LOGIN"|translate}}
                </button>
            </div>
        </div>
        <div class="row">
            <form [formGroup]="form" (ngSubmit)="resetB2cAccount()" style="margin-top: 15px;" [ngClass]="{'arabic-css': isArabic()}" *ngIf="!sucessResetPWD">

                <label>{{"NEW_PASSWORD"|translate}}</label>
                <div class="input-group username-div">
                    <input type="password" class="form-control" name="login_user" formControlName="password" id="password" required>
                    <span (click)="togglePassword('password')" style="cursor:pointer" class="input-group-addon"><i id="pass-icon_password"
                        class="fa fa-eye fa-fw"></i></span>
                </div>
                <div *ngIf="f.password.touched && f.password.invalid" class="alert alert-danger">
                    <div *ngIf="f.password.errors.required">{{"PASSWORD_REQUIRED"|translate}}</div>
                </div>

                <label>{{"CONFIRM_PASSWORD"|translate}}</label>
                <div class="input-group username-div">
                    <input type="password" class="form-control" name="login_user"  formControlName="confirm_password" id="password_repeat" required>
                    <span (click)="togglePassword('password_repeat')" style="cursor:pointer" class="input-group-addon"><i id="pass-icon_password_repeat"
                        class="fa fa-eye fa-fw"></i></span>
                </div>
                <div *ngIf="f.confirm_password.touched && f.confirm_password.invalid" class="alert alert-danger">
                    <div *ngIf="f.confirm_password.errors.required">{{"PASSWORD_REQUIRED"|translate}}</div>
                    <div *ngIf="f.confirm_password.errors.confirmedValidator">{{"PASSWORD_MUST_MATCH"|translate}}</div>
                </div>

                <button [disabled]="!form.valid" type="submit"  style="width: 100%; margin-top: 10px; font-size: 15px" id="reset_pwd_button">
                    {{"FORGET_PWD_TITLE"|translate}}
                 </button>

            </form>
        </div>
    </div>

</div>