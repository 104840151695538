export const environment = {
    production: true,
    CLIENTNAME: 'madiha',
    assets: 'madiha',
    clientDisplayName: 'Madiha Voyage',
    staticApi: 'https://static-api.algebratec.com/',
    serverURL: 'https://b2c-api.algebratec.com',
    key: 'JF21485CSeJl0CYaFUH215',
    phone: [],
    proAccessUrl: 'http://ticketsdz.com/',
	delivery : true,
    mode: 'B2C'
};
