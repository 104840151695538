import { Component, OnInit } from '@angular/core';
import { BaseService } from './shared/services/base.service';
import { ShareVars } from "./shared/services/share.vars";
import { environment } from "../environments/environment";
import { count } from 'console';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit {

  varsRef: any;
  userType = "B2C";
  
  
  constructor(private baseService: BaseService) {    

  }

  ngOnInit() {
    this.varsRef = ShareVars;
    if (environment.mode == 'B2B') { 
      this.baseService.disableB2C();
    }
    this.baseService.initLanguage();
  }

  

  isB2BUser(): boolean {
    return this.baseService.isB2BUserCookies();
  }

  getIsBusy(): boolean {
    return ShareVars.isBusy;
  }

  getShowTreeSearch(): boolean {
    return ShareVars.showTreeSearch;
  }

}
