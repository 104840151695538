import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { B2cAccountConfirmationPageComponent } from './shared/components/b2c-account-confirmation-page/b2c-account-confirmation-page.component'
import { CommunicationComponent } from './main/components/communication/communication.component';
import { B2cResetPasswordPageComponent } from './shared/components/b2c-reset-password-page/b2c-reset-password-page.component';
import { InvoiceComponent } from './main/components/invoice/invoice.component';
import { InvoiceDetailsComponent } from './main/components/invoice-details/invoice-details.component';
import { FlightEditTicketComponent } from './main/components/flight-edit-ticket/flight-edit-ticket.component';
import { FlightCalendarComponent } from "./shared/components/flight-calendar/flight-calendar.component";

// import { AssuranceManageComponent } from './main/components/assurance-manage/assurance-manage.component';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home'
    },
    {
        path: 'home',
        loadChildren: './main/main.module#MainModule'
    },
    {
        path: 'communication',
        component: CommunicationComponent
    },
	{
        path: 'invoices',
        component: InvoiceComponent
    },
	{
		path: 'invoices/:id',
		component: InvoiceDetailsComponent

	},

    // {
    //     path: 'assurance-manage',
    //     component: AssuranceManageComponent
    // },
    {
        path: 'flight-search',
        loadChildren: './flight-booking/flight-booking.module#FlightBookingModule'
    },
    {
        path: 'package-search',
        loadChildren: './package-booking/package-booking.module#PackageBookingModule'
    },
    {
        path: 'hotel-search',
        loadChildren: './hotel-booking/hotel-booking.module#HotelBookingModule'
    },
    {
        path: 'charter-search',
        loadChildren: './charter-booking/charter-booking.module#CharterBookingModule'
    },
    {
        path: 'user-dashboard',
        loadChildren: './user-dashboard/user-dashboard.module#UserDashboardModule'
    },
    {
        path: 'visa',
        loadChildren: './visa/visa.module#VisaModule'
    },
    {
        path: 'flight-calendar',
        component: FlightCalendarComponent,
    },
    {
        path: 'confirmPayment',
        loadChildren: './shared/components/payment-loading-page/payment-loading-page.module#PayementLoadingPageModule'
    },
    {
        path: 'b2b',
        loadChildren: './b2b/b2b.module#B2BModule'
    },
    {
        path: 'confirmAccount/:token',
        component: B2cAccountConfirmationPageComponent
    },
    {
        path: 'b2c/reset-password/:token',
        component: B2cResetPasswordPageComponent
    },
	{
        path: 'edit-flight-bookings/eticket',
        component: FlightEditTicketComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(ROUTES)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
