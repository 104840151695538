export class DateService {

    constructor() {

    }

    public static getTodayFullDateUTC() {
        const todayFullDate = {
            year: new Date().getUTCFullYear(),
            month: new Date().getUTCMonth() + 1,
            day: new Date().getUTCDate(),
            hours: new Date().getUTCHours(),
            minutes: new Date().getUTCMinutes(),
            seconds: new Date().getUTCSeconds(),
        }

        return todayFullDate;
    }

    public static getFullDateUTC(dateX: string): string {

        let dateObj;
        dateObj = this.toNewDate(this.toObjectDate(dateObj, dateX)).toDateString().split(" ");
        const tempCheckIn = dateObj[1];
        dateObj[1] = dateObj[2];
        dateObj[2] = tempCheckIn;
        dateObj = dateObj.join(" ").replace(" ", ", ");
        return dateObj;
    }

    public static getTodayDate() {
        const todayDate = {
            year: new Date().getFullYear(),
            month: new Date().getMonth() + 1,
            day: new Date().getDate()
        }

        return todayDate;
    }

    public static getDateFromDays(day) {
        const tempDate = new Date();
        tempDate.setDate(tempDate.getDate() + day);

        const theDate = {
            year: tempDate.getFullYear(),
            month: tempDate.getMonth() + 1,
            day: tempDate.getDate()
        }

        return theDate;
    }


    public static isEmptyDate(X): boolean {
        if (X["year"] == null || X["month"] == null || X["day"] == null) {
            return true;
        } else {
            return false;
        }
    }

    public static toNewDate(X): Date {
        return new Date(X["year"], X["month"] - 1, X["day"], 0, 0, 0, 0);
    }

    public static setDate(X, Y) {
        X = { year: Y.getFullYear(), month: Y.getMonth() + 1, day: Y.getDate() }
        return X;
    }

    public static initEmptyDate(X) {
        X = { year: null, month: null, day: null }
        return X;
    }

    public static toStringDate(X): string {
        return X["year"] + '-' + (X["month"] < 10 ? '0' + X["month"] : X["month"]) + '-' + (X["day"] < 10 ? '0' + X["day"] : X["day"])
    }


    public static toObjectDate(X, Y: string) {
        X = this.initEmptyDate(X);
        if (Y == null || Y === undefined) {
            return X;
        }
        X["year"] = +Y.slice(0, 4);
        X["month"] = +Y.slice(5, 7);
        X["day"] = +Y.slice(8, 10);
        return X;
    }


    public static getDateFromYears(X) {
        const dateX = this.getTodayDate();
        dateX["year"] = dateX["year"] + X;
        return dateX;

    }


    public static convertDateInPastYear(X, stringDate, max) {
        let date = new Date(stringDate);
        if(max){
            date.setDate(date.getDate() + 1);
        }
        let objectDate = [];

        objectDate = this.setDate(objectDate, date);
        objectDate["year"] = objectDate["year"] - X;
        
        return objectDate;
    }
}