import { Injectable } from '@angular/core';
import { WebService } from '@app/web.service';
import {
    HOME_ROUTE, FLIGHT_BOOKING_ROUTE, HOTEL_BOOKING_ROUTE, PACKAGE_BOOKING_ROUTE, USER_DASHBOARD_ROUTE,
    VISA_ROUTE, AVAILABILITY_CHILD_ROUTE, CONFIRMATION_CHILD_ROUTE, FLIGHT_PAX, PACKAGE_PAX,
    HOTEL_ITEM_DETAILS, HOTEL_PAX, VISA_PAX, E_CONFIRMATION_ROUTE, E_CONFIRMATION_CHILD, B2B_ROUTE, LOGIN_B2B, MANAGE_HOTELS_B2B,
    MANAGE_FLIGHTS_B2B, MANAGE_FLIGHT_ETICKET_B2B, MANAGE_HOTELS_VOUCHER_B2B, ACCOUNT_HISTORY_B2B, FLIGHT_SCHEDULE, MANAGE_AGENT_B2B, MANAGE_FLIGHT_AMADEUS_MARKUP_B2B,
    MANAGE_VISA_B2B, CREATE_VISA, AGENCY_DASHBOARD_B2B, FORGET_PASSWORD_B2B, CHANGE_PASSWORD_B2B, MANAGE_PACKAGE_BOOKINGS_B2B, MANAGE_PACKAGES_B2B, MANAGE_PACKAGE_VOUCHER_B2B, REGISTER_B2B, PACKAGE_DETAILS_PAGE, COMMUNICATIONS_DETAILS_PAGE, INSURANCES_MANAGE_PAGE, CREATE_INSURANCE,
	CHARTER_PAX, CHARTER_MANAGE_PAGE, CHARTER_ETICKET_PAGE, MARKUP_HISTORY_B2B, CREATE_CHARTER, HOTEL_VOUCHER_ROUTE,INVOICES_PAGE, CREATE_PACKAGE, FLIGHT_CALENDAR, EDIT_FLIGHT_TICKET
} from "./routes.pages";

@Injectable()
export class NavigationService {

    constructor(private platformService: WebService) {

    }

    navigate(route, params?) {

        if (typeof route === typeof '') {
            route = [route];
        }

        if (params == null) {
            params = {};
        }
        this.platformService.navigate(route, params);
    }

    navigateBack() {
        this.platformService.backToPreviousPage();
    }

    urlParamsObservable() {
        return this.platformService.queryParamsObservable();
    }

    /* nav-bar route */

    goToLoginPage() {
        this.navigate(HOME_ROUTE + '/login');
    }

    goToSignupPage() {
        this.navigate(HOME_ROUTE + '/signup');
    }

    goToHomePage() {
        const params = {};
        this.navigate(HOME_ROUTE, params);
    }

    goToUserDashboard() {
        this.navigate(USER_DASHBOARD_ROUTE);
    }



    /* flight booking route */

    goToFlightAvailabilityPage(params?) {
        this.navigate(FLIGHT_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE, params);
    }
    gotToFlightCalendar(){
        this.navigate(FLIGHT_CALENDAR)
    }

    goToPassengerPage() {
        const params = {};
        this.navigate(FLIGHT_BOOKING_ROUTE + FLIGHT_PAX, params);
    }

    goToFlightConfirmationPage() {
        const params = {};
        this.navigate(FLIGHT_BOOKING_ROUTE + CONFIRMATION_CHILD_ROUTE, params);
    }


    /* hotel booking route */

    goToHotelAvailabilityPage(params?) {
        this.navigate(HOTEL_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE, params);
    }

    goToHotelItemPage() {
        const params = {};
        this.navigate(HOTEL_BOOKING_ROUTE + HOTEL_ITEM_DETAILS, params);
    }

    goToHotelGuestPage() {
        const params = {};
        this.navigate(HOTEL_BOOKING_ROUTE + HOTEL_PAX, params);
    }

    goToHotelConfirmationPage() {
        const params = {};
        this.navigate(HOTEL_BOOKING_ROUTE + CONFIRMATION_CHILD_ROUTE, params);
    }

    goToHotelVoucherPage(_params = {}) {
        const params = _params;
        this.navigate(HOTEL_BOOKING_ROUTE + HOTEL_VOUCHER_ROUTE, params);
    }

    /* package booking route */

    goToPackageAvailabilityPage(params?) {
        this.navigate(PACKAGE_BOOKING_ROUTE + AVAILABILITY_CHILD_ROUTE, params);
    }

    goToPackageDetailsPage(){
        this.navigate(PACKAGE_BOOKING_ROUTE+PACKAGE_DETAILS_PAGE)
    }

    goToPackageTravellerPage() {
        const params = {};
        this.navigate(PACKAGE_BOOKING_ROUTE + PACKAGE_PAX, params);
    }

    goToPackageConfirmationPage() {
        const params = {};
        this.navigate(PACKAGE_BOOKING_ROUTE + CONFIRMATION_CHILD_ROUTE, params);
    }

    /* visa route */

    goToVisaPassengerPage() {
        this.navigate(VISA_ROUTE + VISA_PAX);
    }

    /* charter route */
    goToCharterPassengerPage() {
        const params = {};
        this.navigate(FLIGHT_BOOKING_ROUTE + CHARTER_PAX, params);
    }

    /* b2b route */

    goToB2BLogin() {
        this.navigate(B2B_ROUTE + LOGIN_B2B);
    }

    goToB2BManageFlightBookings() {
        this.navigate(B2B_ROUTE + MANAGE_FLIGHTS_B2B);
    }

    goToB2BManageVisaBookings() {
        this.navigate(B2B_ROUTE + MANAGE_VISA_B2B);
    }

    goToB2BManageHotelBookings() {
        this.navigate(B2B_ROUTE + MANAGE_HOTELS_B2B);
    }

    goToB2BManagePackageBookings() {
        this.navigate(B2B_ROUTE + MANAGE_PACKAGE_BOOKINGS_B2B);
    }

    goToB2BManagePackages() {
        this.navigate(B2B_ROUTE + MANAGE_PACKAGES_B2B);
    }

    goToB2BFlightETicket(id) {
        this.navigate(B2B_ROUTE + MANAGE_FLIGHT_ETICKET_B2B + id);
    }

    goToB2BFlightAmadeusMarkup() {
        this.navigate(B2B_ROUTE + MANAGE_FLIGHT_AMADEUS_MARKUP_B2B);
    }

    goToB2BHotelVoucher(ref) {
        this.navigate(B2B_ROUTE + MANAGE_HOTELS_VOUCHER_B2B + ref);
    }

    goToB2BPackageVoucher(id) {
        this.navigate(B2B_ROUTE + MANAGE_PACKAGE_VOUCHER_B2B + id);
    }

    goToB2BAccountHistory() {
        this.navigate(B2B_ROUTE + ACCOUNT_HISTORY_B2B);
    }

    goToB2BFlightSchedule() {
        this.navigate(B2B_ROUTE + FLIGHT_SCHEDULE);
    }

    goToB2BManageAgent() {
        this.navigate(B2B_ROUTE + MANAGE_AGENT_B2B);
    }

    goToB2BMarkupHistory() {
        this.navigate(B2B_ROUTE + MARKUP_HISTORY_B2B);
    }

    goToB2BAgencyDashboard() {
        this.navigate(B2B_ROUTE + AGENCY_DASHBOARD_B2B);
    }

    goToCommunicationsPage() {
        this.navigate(COMMUNICATIONS_DETAILS_PAGE);
    }
	goToInvoicesPage() {
        this.navigate(INVOICES_PAGE);
    }
    
	goToInvoiceDetailsPage(id){
		this.navigate(INVOICES_PAGE+"/"+id);
	}

    goToManageCharterPage() {
        this.navigate(B2B_ROUTE + CHARTER_MANAGE_PAGE);
    }

    goToEticketCharterPage() {
        this.navigate(B2B_ROUTE + CHARTER_ETICKET_PAGE);
    }

    goToManageInsurancePage() {
        this.navigate(B2B_ROUTE + INSURANCES_MANAGE_PAGE);
    }

    goToB2BForgetPassword() {
        this.navigate(B2B_ROUTE + FORGET_PASSWORD_B2B);
    }

    goToB2BChangePassword() {
        this.navigate(B2B_ROUTE + CHANGE_PASSWORD_B2B);
    }

    goToB2BRegister() {
        this.navigate(B2B_ROUTE + REGISTER_B2B);
    }

    goToInsurancePage() {
        this.navigate(B2B_ROUTE + CREATE_INSURANCE);
    }

    goToVisaPage(){
        this.navigate(B2B_ROUTE + CREATE_VISA);
    }

    goToCharterPage() {
        this.navigate(B2B_ROUTE + CREATE_CHARTER);
    }

    goToPackagePage() {
        this.navigate(B2B_ROUTE + CREATE_PACKAGE);
    }


    /* E confirmation */

    goToEPConfirmation(params) {
        this.navigate(E_CONFIRMATION_ROUTE + E_CONFIRMATION_CHILD, params);
    }
	goToEditFlightBookingPage(params){
		this.navigate(EDIT_FLIGHT_TICKET, params);
	}

}
