<section>
  <div class="row page-title">
    <div class="clear-padding text-center flight-title flight-bg">
      <h1>{{"INVOICES"|translate}}</h1>        
    </div>
  </div>
</section>

<div class="top-baner bg-grey-2">
  <div class="container clear-padding">

    <div class="row no-margin">
      <div class="find-form no-padding col-xs-12 col-md-12">

        <div class="col-sm-12 col-xs-12 clear-padding">

          <table id="table1" class="table table-align">
            <thead>
              <tr>   
                
                <th scope="col">
                  {{"No."|translate}} 
                </th>
                <th scope="col">
                  {{"DATE"|translate}} 
                </th>                            

                <th scope="col">
                  {{"DUE_DATE"|translate}}
                </th>

                <th scope="col">
                  {{"STATUT"|translate}}
                </th>
                 <th scope="col">
                  {{"PAID_AMOUNT"|translate}}
                </th>
                 <th scope="col">
                  {{"TOTAL"|translate}}
                </th>
              </tr>
            </thead>

            <tbody>
              <ng-container *ngFor="let invoice of invoices; index as i">
                <tr class="table-line" [ngClass]="{'unread': !invoice.read}" valign="middle" (click)="openInvoice(invoice)">
                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold sender">{{invoice.invoice_number}}</span>
                  </td>

                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold">{{formatDate(invoice.creation_date)}}</span>
                  </td>

                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold">{{formatDate(invoice.due_date)}}</span>
                  </td>

                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold state" [ngClass]="invoice.state|lowercase">{{invoice.state|translate|uppercase}}</span>
                  </td>
                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold">{{formatPrice(invoice.paid_amount)}}</span>
                  </td>

                  <td>
                    <span class="icon-location"></span>
                    <span class="text-bold">{{formatPrice(invoice.total_price)}}</span>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <div class="pagination">
            <b>{{total}} </b>  <span style="text-transform: lowercase;">{{"INVOICES"|translate}}</span>
            <div class="per-page">
              <span style="margin-right: 5px;">{{"PER_PAGE"|translate}} : </span>
              <select name="perPage" [(ngModel)]="perPage" (change)="page=1;getAllInvoices()">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>                
              </select>
            </div>
            <div class="per-page">
              <span style="margin-right: 5px;">{{"PAGE"|translate}} : </span>
              <!-- <input type="number" [(ngModel)]="perPage"  min="1" max="100" style="margin-right: 5px;"> -->
              <select name="perPage" [(ngModel)]="page" (change)="getAllInvoices()">
                <option *ngFor="let page_i of numbers" [value]="page_i + 1">
                  {{page_i+1}}
                </option>
                           
              </select>
            </div>
          </div>

        </div>

      </div>
    </div>
  
  </div>
</div>