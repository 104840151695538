<section id="footer" [ngClass]="{'arabic-css': isArabic()}">
    <footer>

        <div class="main-footer row">
            <div class="container clear-padding">
                <div class="col-md-2 col-sm-0"></div>
                <div class="col-md-4 col-sm-6 about-box">
                    <h3>{{clientName}}</h3>
                    <p class="justify-content-around">L’Agence MADIHA TOURISME ET VOYAGES (AMTV) a été créée en 1999, par Monsieur Benzerafa Bachir. AMTV est une agence de voyage et de tourisme agréé : I.A.T.A et A.T.A.F. Notre objectif est de promouvoir l’image de l’Algérie. L’effectif du personnel de l’Agence MADIHA se compose de cadres universitaires formés pour le tourisme avec un parcours professionnel riche d’expériences dans le domaine.</p>
                    <a href="#">{{"READ_MORE"|translate}}</a>
                </div>

                <div class="col-md-1 col-sm-1 contact-box"></div>
                <div class="col-md-4 col-sm-6 contact-box">
                    <h4>{{"CONTACT_US"|translate}}</h4>
                    <p><i class="fa fa-user"></i> Hocine Benzerafa</p>
                    <p><i class="fa fa-home"></i> C7X7+MW , Mila Algérie</p>
                    <p><i class="fa fa-phone"></i> +213 31 48 03 03</p>
                    <p><i class="fa fa-phone"></i> +213 661 82 61 56</p>
                    <p><i class="fa fa-envelope-o"></i>hocinm@gmail.com</p>                    
                </div>
                <div class="clearfix"></div>
                <div class="col-md-12 text-center we-accept">
                    <h4>{{"SOCIAL_MEDIA"|translate}}</h4>
                    <div class="social-media">
                        <ul class="social-media-footer">
                            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="#"><i class="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i class="fa fa-youtube"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-footer-nav row">
            <div class="container clear-padding">
                <div class="col-md-6 col-sm-6">
                    <p>Copyright &copy; {{currentYear}}. All Rights Reserved.</p>
                </div>

                <div class="go-up">
                    <a (click)="goToTop()"><i class="fa fa-arrow-up"></i></a>
                </div>
            </div>
        </div>
    </footer>
</section>