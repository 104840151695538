import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "../../services/navigation.service";
import { LoginService } from "../../services/login.service";
import { BaseService } from "../../services/base.service";

@Component({
  selector: 'app-b2c-account-confirmation-page',
  templateUrl: './b2c-account-confirmation-page.component.html',
  styleUrls: ['./b2c-account-confirmation-page.component.scss']
})
export class B2cAccountConfirmationPageComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private navigationService: NavigationService, private loginService: LoginService, private baseService: BaseService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      data => this.confirmB2cAccount(data["token"]),
      error => this.navigationService.goToHomePage()
    );
  }

  confirmB2cAccount(token){
    this.baseService.setToken(token);
    this.loginService.confirmB2cAccount(token).subscribe(
      data => {
          this.navigationService.goToHomePage();       
      },
      error => {

      },
      () => {
      }
    );
  }

}
