import * as tslib_1 from "tslib";
import { BaseService } from '@app/shared/services/base.service';
import { UrlBuilder, PACKAGES_AVAILABILITY_API_URL } from '@app/shared/services/urls_b2c';
import { PACKAGES_AVAILABILITY_B2B_URL } from '@app/shared/services/urls_b2b';
import { cloneDeep } from 'lodash';
import * as i0 from "@angular/core";
import * as i1 from "./base.service";
// todo: move this variable to ./urls_b2c.ts
var PACKAGE_SEARCH_PARAMS = 'packageSearchParams';
var PackagesService = /** @class */ (function () {
    function PackagesService(baseService) {
        this.baseService = baseService;
        this.roomsToBook = null;
        this.packageRoomsNumber = null;
        this.departureID = null;
        this.packageID = null;
        this.subPackageID = null;
        this.packageObj = null;
        this.adultTotalNumber = null;
        this.childTotalNumber = null;
        this.packageBookingConfirmationRequest = null;
        this.packageRoomRate = null;
        this.confirmArray = null;
        this.packageBookingSearchParams = null;
        this.packageBookingAvailabilityResults = null;
        this.tabDetailIndex = 2;
        this.packageTotalPrice = null;
        this.selectedPriceID = null;
    }
    PackagesService.prototype.removeHotelPaxData = function () {
        this.setPackageBookingPackageRoomsToBook(null);
        this.setPackageBookingPackageRoomsNumber(null);
        this.setPackageBookingPackageDepartureID(null);
        this.setPackageBookingPackageID(null);
        this.setPackageBookingSubPackageID(null);
        this.setPackageBookingPackageObject(null);
        this.setPackageBookingConfirmationRequest(null);
    };
    PackagesService.prototype.removeAllData = function () {
        this.roomsToBook = null;
        this.packageRoomsNumber = null;
        this.departureID = null;
        this.packageID = null;
        this.subPackageID = null;
        this.packageObj = null;
        this.adultTotalNumber = null;
        this.childTotalNumber = null;
        this.packageBookingConfirmationRequest = null;
        this.packageBookingAvailabilityResults = null;
    };
    PackagesService.prototype.getPackageBookingSearchParams = function () {
        return this.packageBookingSearchParams;
    };
    PackagesService.prototype.setPackageBookingSearchParams = function (searchData) {
        this.packageBookingSearchParams = cloneDeep(searchData);
    };
    PackagesService.prototype.getPackageBookingAvailabilityResults = function () {
        return this.packageBookingAvailabilityResults;
    };
    PackagesService.prototype.setPackageBookingAvailabilityResults = function (results) {
        (results) ? this.packageBookingAvailabilityResults = cloneDeep(results) : this.packageBookingAvailabilityResults = null;
    };
    PackagesService.prototype.getPackageBookingAdultTotalNumber = function () {
        return this.adultTotalNumber;
    };
    PackagesService.prototype.setPackageBookingAdultTotalNumber = function (adults) {
        this.adultTotalNumber = adults;
    };
    PackagesService.prototype.getPackageBookingChildTotalNumber = function () {
        return this.childTotalNumber;
    };
    PackagesService.prototype.setPackageBookingChildTotalNumber = function (child) {
        this.childTotalNumber = child;
    };
    PackagesService.prototype.getPackageConfirmArray = function () {
        return this.confirmArray;
    };
    PackagesService.prototype.setPackageConfirmArray = function (confirmArray) {
        this.confirmArray = confirmArray;
    };
    PackagesService.prototype.getPackageBookingPackageObject = function () {
        return this.packageObj;
    };
    PackagesService.prototype.setPackageBookingPackageObject = function (data) {
        if (data != null) {
            this.packageObj = tslib_1.__assign({}, data);
        }
        else {
            this.packageObj = data;
        }
    };
    PackagesService.prototype.getPackageBookingPackageRoomsToBook = function () {
        return this.roomsToBook;
    };
    PackagesService.prototype.setPackageBookingPackageRoomsToBook = function (rooms) {
        if (rooms != null) {
            this.roomsToBook = rooms.slice();
        }
        else {
            this.roomsToBook = null;
        }
    };
    PackagesService.prototype.getPackageBookingPackageRoomsNumber = function () {
        return this.packageRoomsNumber;
    };
    PackagesService.prototype.setPackageBookingPackageRoomsNumber = function (roomsNumber) {
        this.packageRoomsNumber = roomsNumber;
    };
    PackagesService.prototype.setPackageRoomRate = function (roomRate) {
        this.packageRoomRate = roomRate;
    };
    PackagesService.prototype.getPackageRoomRate = function () {
        return this.packageRoomRate;
    };
    PackagesService.prototype.getPackageBookingPackageDepartureID = function () {
        return this.departureID;
    };
    PackagesService.prototype.setPackageBookingPackageDepartureID = function (departureID) {
        this.departureID = departureID;
    };
    PackagesService.prototype.getPackageBookingPackageID = function () {
        return this.packageID;
    };
    PackagesService.prototype.setPackageBookingPackageID = function (packageID) {
        this.packageID = packageID;
    };
    PackagesService.prototype.getPackageBookingSubPackageID = function () {
        return this.subPackageID;
    };
    PackagesService.prototype.setPackageBookingSubPackageID = function (subPackageID) {
        this.subPackageID = subPackageID;
    };
    PackagesService.prototype.setPackageBookingPackageTotalPrice = function (price) {
        this.packageTotalPrice = price;
    };
    PackagesService.prototype.getPackageBookingPackageTotalPrice = function () {
        return this.packageTotalPrice;
    };
    PackagesService.prototype.setPackageSelectedPriceID = function (id) {
        this.selectedPriceID = id;
    };
    PackagesService.prototype.getPackageSelectedPriceID = function () {
        return this.selectedPriceID;
    };
    PackagesService.prototype.getPackageBookingConfirmationRequest = function () {
        return this.packageBookingConfirmationRequest;
    };
    PackagesService.prototype.setPackageBookingConfirmationRequest = function (request) {
        this.packageBookingConfirmationRequest = request;
    };
    PackagesService.prototype.getPackges = function (params) {
        var urlBuilder;
        (this.baseService.b2bUserType == "B2B") ? urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_B2B_URL) : urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_API_URL);
        for (var i = 0; i < params['room']; i++) {
            urlBuilder.addParam("adults_" + (i + 1), params['adults_' + (i + 1)]);
            urlBuilder.addParam("children_" + (i + 1), params['children_' + (i + 1)]);
            if (params['children_' + (i + 1)] > 0) {
                for (var j = 0; j < params['children_' + (i + 1)]; j++) {
                    urlBuilder.addParam('child_age_' + (i + 1) + '_' + (j + 1), params['child_age_' + (i + 1) + '_' + (j + 1)]);
                }
            }
        }
        urlBuilder.addParam('customer_nationality', params['customer_nationality'])
            .addParam('departureDate', params['departureDate'])
            .addParam('destinationCity', params["destinationCity"].split("+").join("%2B"))
            .addParam('persons', params['persons'])
            .addParam('room', params['room']);
        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    };
    PackagesService.prototype.searchPackages = function (params) {
        var urlBuilder;
        (this.baseService.b2bUserType == "B2B") ? urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_B2B_URL) : urlBuilder = new UrlBuilder(PACKAGES_AVAILABILITY_API_URL);
        for (var i = 0; i < params['room']; i++) {
            urlBuilder.addParam("adults_" + (i + 1), params['adults_' + (i + 1)]);
            urlBuilder.addParam("children_" + (i + 1), params['children_' + (i + 1)]);
            if (params['children_' + (i + 1)] > 0) {
                for (var j = 0; j < params['children_' + (i + 1)]; j++) {
                    urlBuilder.addParam('child_age_' + (i + 1) + '_' + (j + 1), params['child_age_' + (i + 1) + '_' + (j + 1)]);
                }
            }
        }
        var availableParams = ['customer_nationality',
            'beginDate',
            'endDate',
            'destinationCity',
            'persons',
            'room',
            'page',
            'departureCityID'];
        for (var _i = 0, availableParams_1 = availableParams; _i < availableParams_1.length; _i++) {
            var paramKey = availableParams_1[_i];
            if (paramKey in params && params[paramKey]) {
                var value = params[paramKey];
                if (paramKey === 'destinationCity') {
                    value = value.split("+").join("%2B");
                }
                urlBuilder.addParam(paramKey, value);
            }
        }
        urlBuilder.addParam('per_page', params['per_page'] || 10);
        return this.baseService.tokenGetRequest(urlBuilder.getUrl());
    };
    PackagesService.prototype.savePackageSearchParams = function (params) {
        this.baseService.saveData(PACKAGE_SEARCH_PARAMS, params);
    };
    PackagesService.prototype.getPackageSearchParams = function () {
        return (this.baseService.getData(PACKAGE_SEARCH_PARAMS));
    };
    PackagesService.prototype.deletePackageSearchParams = function () {
        this.baseService.deleteData(PACKAGE_SEARCH_PARAMS);
    };
    PackagesService.ngInjectableDef = i0.defineInjectable({ factory: function PackagesService_Factory() { return new PackagesService(i0.inject(i1.BaseService)); }, token: PackagesService, providedIn: "root" });
    return PackagesService;
}());
export { PackagesService };
